class ReactNativeWebDimensionHelper {
    constructor() {}

    get window() {
        return {
            width: Math.min(window.innerWidth, 1920),
            height: Math.min(window.innerHeight, 1080 - 60),
        };
    }

    get(p) {
        return {
            width: Math.min(window.innerWidth, 1920),
            height: Math.min(window.innerHeight, 1080 - 60),
        };
    }
}

const DimensionsHelper = new ReactNativeWebDimensionHelper();

export default DimensionsHelper;
