export default {
    components: {
        ui: {
            handwritecanvas: "components.ui.handwritecanvas",
            marktextrender: "components.ui.marktextrender",
            audiorecorder: "components.ui.audiorecorder",
            ellipsistext: "components.ui.ellipsistext",
            htmleditor: "components.ui.htmleditor",
            letter: "components.ui.letter",
            selectunittype: "components.ui.selectunittype",
            searchimageonline:"components.ui.searchimageonline",
            generalArticle:"components.ui.generalArticle",
            collapsiblePanel: "components.ui.collapsiblePanel",
            marktexteditor_contentedit: "components.ui.marktexteditor_contentedit",
            units: {
                base: {
                    referenceanswerview: "components.ui.units.base.referenceanswerview",
                    basepracticemultiunit: "components.ui.units.base.basepracticemultiunit",
                    basepracticeunitwithgeneralarticle: "components.ui.units.base.basepracticeunitwithgeneralarticle",
                    basearticlepracticesunit: "components.ui.units.base.basearticlepracticesunit",
                    basepracticemultiunitwithsharedtop: "components.ui.units.base.basepracticemultiunitwithsharedtop",
                    posc1and2and3unit: "components.ui.units.base.posc1and2and3unit"
                },
                pozb3unit: "components.ui.units.pozb3unit",
                psbx1unit: "components.ui.units.psbx1unit",
                pslx1unit: "components.ui.units.pslx1unit",
                pslx2unit: "components.ui.units.pslx2unit",
                pscx1unit: "components.ui.units.pscx1unit",
                pobx2unit: "components.ui.units.pobx2unit",
                pocx1unit: "components.ui.units.pocx1unit",
                pocx2unit: "components.ui.units.pocx2unit",
                pocx4unit: "components.ui.units.pocx4unit",
                poex1unit: "components.ui.units.poex1unit",
                poeo1unit: "components.ui.units.poeo1unit",
                poka3unit: "components.ui.units.poka3unit",
                poka4unit: "components.ui.units.poka4unit",
                pola1unit: "components.ui.units.pola1unit",
                pomx1unit: "components.ui.units.pomx1unit",
                pomx2unit: "components.ui.units.pomx2unit",
                pomx4unit: "components.ui.units.pomx4unit",
                psuo1unit: "components.ui.units.psuo1unit",
                pssd2unit: "components.ui.units.pssd2unit",
                posb1unit: "components.ui.units.posb1unit",
                pska5unit: "components.ui.units.pska5unit",
                pssd1unit: "components.ui.units.pssd1unit",
                psso1unit: "components.ui.units.psso1unit",
                ai: {
                    psdo1aiview: "components.ui.units.ai.psdo1aiview",
                    pssd1aiview: "components.ui.units.ai.pssd1aiview",
                },
                single: {
                    poca3singleunit: "components.ui.units.single.poca3singleunit",
                    poka1singleunit: "components.ui.units.single.poka1singleunit",
                    poka2singleunit: "components.ui.units.single.poka2singleunit",
                    poka3singleunit: "components.ui.units.single.poka3singleunit",
                    poka4singleunit: "components.ui.units.single.poka4singleunit",
                    pska5singleunit: "components.ui.units.single.pska5singleunit",
                    poea6singleunit: "components.ui.units.single.poea6singleunit",
                    pola1singleunit: "components.ui.units.single.pola1singleunit",
                    psac1singleunit: "components.ui.units.single.psac1singleunit",
                    psba4singleunit: "components.ui.units.single.psba4singleunit",
                    psbb1singleunit: "components.ui.units.single.psbb1singleunit",
                    psfa2singleunit: "components.ui.units.single.psfa2singleunit",
                    pska1singleunit: "components.ui.units.single.pska1singleunit",
                },
            },
        },
    },
    layouts: {
        account: "layouts.account",
        admin: "layouts.admin",
        main: "layouts.main",
        portal: "layouts.portal",
        plain: "layouts.plain",
        practice: "layouts.practice",
        room: "layouts.room",
        navbars: {
            admin: "layouts.navbars.admin",
            auth: "layouts.navbars.auth",
            portal: "layouts.navbars.portal",
            mobile: "layouts.navbars.mobile",
        },
        sidebar: {
            sidebar: "layouts.sidebar.sidebar",
        },
        footers: {
            portalfooter: "layouts.footers.portalfooter",
        },
    },
    screens: {
        account: {
            teacherlogin: "screens.account.teacherlogin",
            agent: "screens.account.agentlogin",
            login: "screens.account.login",
            userlogin: "screens.account.userlogin",
            userregister: "screens.account.userregister",
            forgotpassword: "screens.account.forgotpassword",
            changepassword: "screens.account.changepassword",
            register: "screens.account.register",
            registerpartner: "screens.account.registerpartner",
            activeteacher: "screens.account.activeteacher",
        },
        admin: {
            agent: {
                management: {
                    add: "screens.admin.agent.management.add",
                    code: "screens.admin.agent.management.code",
                    details: "screens.admin.agent.management.details",
                    edit: "screens.admin.agent.management.edit",
                    list: "screens.admin.agent.management.list",
                    statistic: "screens.admin.agent.management.statistic",
                    withdrawallist: "screens.admin.agent.management.withdrawallist",
                    withdrawaldetails: "screens.admin.agent.management.withdrawaldetails",
                    agentapplicationlist: "screens.admin.agent.management.agentapplicationlist",
                    agentapplicationdetails: "screens.admin.agent.management.agentapplicationdetails",
                    incomestatistic: "screens.admin.agent.management.incomestatistic",
                },
                redenvelope: "screens.admin.agent.redenvelope",
                statistic: "screens.admin.agent.statistic",
                wallet: "screens.admin.agent.wallet",
                withdrawal: "screens.admin.agent.withdrawal",
                withdrawalcomplete: "screens.admin.agent.withdrawalcomplete",
                studentcapitalflow: "screens.admin.agent.studentcapitalflow",
                agentreferralcode: "screens.admin.agent.agentreferralcode",
                bankcard: {
                    add: "screens.admin.agent.bankcard.add",
                    details: "screens.admin.agent.bankcard.details",
                    edit: "screens.admin.agent.bankcard.edit",
                },
                subagent: {
                    list: "screens.admin.agent.subagent.list",
                    add: "screens.admin.agent.subagent.add",
                    edit: "screens.admin.agent.subagent.edit",
                    code: "screens.admin.agent.subagent.code",
                    details: "screens.admin.agent.subagent.details",
                },
                teacheraccount: {
                    list: "screens.admin.agent.teacheraccount.list",
                    add: "screens.admin.agent.teacheraccount.add",
                    edit: "screens.admin.agent.teacheraccount.edit",
                    code: "screens.admin.agent.teacheraccount.code",
                    changepassword: "screens.admin.agent.teacheraccount.changepassword",
                },
                useraccount: {
                    list: "screens.admin.agent.useraccount.list",
                    add: "screens.admin.agent.useraccount.add",
                    edit: "screens.admin.agent.useraccount.edit",
                    code: "screens.admin.agent.useraccount.code",
                    changepassword: "screens.admin.agent.useraccount.changepassword",
                },
            },
            promotion: {
                management: {
                    add: "screens.admin.promotion.management.add",
                    details: "screens.admin.promotion.management.details",
                    edit: "screens.admin.promotion.management.edit",
                    list: "screens.admin.promotion.management.list",
                    shareagentlist: "screens.admin.promotion.management.shareagentlist",
                    shareagentadd: "screens.admin.promotion.management.shareagentadd",
                    statistic: "screens.admin.promotion.management.statistic",
                },
                statistic: "screens.admin.promotion.statistic",
                promotionlist: "screens.admin.promotion.promotionlist",
                promotiondetails: "screens.admin.promotion.promotiondetails",
                sharestatistic: "screens.admin.promotion.sharestatistic",
                redenvelopestatistic: "screens.admin.promotion.redenvelopestatistic",
            },
            class: {
                add: "screens.admin.class.add",
                details: "screens.admin.class.details",
                detailsnew: "screens.admin.class.detailsnew",
                edit: "screens.admin.class.edit",
                homeworkdetails: "screens.admin.class.homeworkdetails",
                list: "screens.admin.class.list",
                listnew: "screens.admin.class.listnew",
                student: {
                    add: "screens.admin.class.student.add",
                    edit: "screens.admin.class.student.edit",
                    editnew: "screens.admin.class.student.editnew",
                    import: "screens.admin.class.student.import",
                    details: "screens.admin.class.student.details",
                    changepassword: "screens.admin.class.student.changepassword",
                },
                shiftstudent: "screens.admin.class.shiftstudent",
                unregstudent: "screens.admin.class.unregstudent",
                code: "screens.admin.class.code",
            },
            course: {
                add: "screens.admin.course.add",
                details: "screens.admin.course.details",
                edit: "screens.admin.course.edit",
                list: "screens.admin.course.list",
            },
            teaching: {
                course: {
                    list: "screens.admin.teaching.course.list",
                },
            },
            bookassignment: {
                add: "screens.admin.bookassignment.add",
                details: "screens.admin.bookassignment.details",
                edit: "screens.admin.bookassignment.edit",
                list: "screens.admin.bookassignment.list",
            },
            bookgroup: {
                list: "screens.admin.bookgroup.list",
                details: "screens.admin.bookgroup.details",
                edit: "screens.admin.bookgroup.edit",
                addmyunit: "screens.admin.bookgroup.addmyunit",
            },
            dashboard: "screens.admin.dashboard",
            dictionary: {
                letter: {
                    add: "screens.admin.dictionary.letter.add",
                    details: "screens.admin.dictionary.letter.details",
                    edit: "screens.admin.dictionary.letter.edit",
                    list: "screens.admin.dictionary.letter.list",
                    imagefield: "screens.admin.dictionary.letter.imagefield",
                },
                lettersen: {
                    add: "screens.admin.dictionary.lettersen.add",
                    details: "screens.admin.dictionary.lettersen.details",
                    edit: "screens.admin.dictionary.lettersen.edit",
                    list: "screens.admin.dictionary.lettersen.list",
                },
                pinyin: {
                    add: "screens.admin.dictionary.pinyin.add",
                    details: "screens.admin.dictionary.pinyin.details",
                    edit: "screens.admin.dictionary.pinyin.edit",
                    list: "screens.admin.dictionary.pinyin.list",
                },
                radical: {
                    add: "screens.admin.dictionary.radical.add",
                    details: "screens.admin.dictionary.radical.details",
                    edit: "screens.admin.dictionary.radical.edit",
                    list: "screens.admin.dictionary.radical.list",
                },
                word: {
                    add: "screens.admin.dictionary.word.add",
                    batchadd: "screens.admin.dictionary.word.batchadd",
                    details: "screens.admin.dictionary.word.details",
                    edit: "screens.admin.dictionary.word.edit",
                    list: "screens.admin.dictionary.word.list",
                    imagefield: "screens.admin.dictionary.word.imagefield",
                    editjson: "screens.admin.dictionary.word.editjson",
                },
                sentence: {
                    details: "screens.admin.dictionary.sentence.details",
                    list: "screens.admin.dictionary.sentence.list",
                    edit: "screens.admin.dictionary.sentence.edit",
                },
            },
            unittask: {
                list: "screens.admin.unittask.list",
                detailslist: "screens.admin.unittask.detailslist",
                makingpicture: "screens.admin.unittask.makingpicture",
                templateconfirmation: "screens.admin.unittask.templateconfirmation",
                prototypedesign: "screens.admin.unittask.prototypedesign",
                templatedevelopment: "screens.admin.unittask.templatedevelopment",
                templatevalidation: "screens.admin.unittask.templatevalidation",
                picturevalidation: "screens.admin.unittask.picturevalidation",
                testcontent: "screens.admin.unittask.testcontent",
                feedbackmodification: "screens.admin.unittask.feedbackmodification",
                publishinglist: "screens.admin.unittask.publishinglist",
                verification: "screens.admin.unittask.verification",
            },
            exam: {
                add: "screens.admin.exam.add",
                details: "screens.admin.exam.details",
                edit: "screens.admin.exam.edit",
                list: "screens.admin.exam.list",
            },
            example: "screens.admin.example",
            composition: {
                assignmentlist: "screens.admin.composition.assignmentlist",
                assignmentpreview: "screens.admin.composition.assignmentpreview",
                assignmentdetails: "screens.admin.composition.assignmentdetails",
                management: {
                    list: "screens.admin.composition.management.list",
                    summary: "screens.admin.composition.management.summary",
                    review: "screens.admin.composition.management.review",
                },
            },
            bbs: {
                list: "screens.admin.bbs.list",
                add: "screens.admin.bbs.add",
                details: "screens.admin.bbs.details",
                edit: "screens.admin.bbs.edit",
                me: "screens.admin.bbs.me",
                message: "screens.admin.bbs.message",
            },
            homework: {
                addunit: "screens.admin.homework.addunit",
                assign: "screens.admin.homework.assign",
                add: "screens.admin.homework.add",
                commentlist: "screens.admin.homework.commentlist",
                classcommentlist: "screens.admin.homework.classcommentlist",
                classreviewlist: "screens.admin.homework.classreviewlist",
                commentdetails: "screens.admin.homework.commentdetails",
                details: "screens.admin.homework.details",
                edit: "screens.admin.homework.edit",
                list: "screens.admin.homework.list",
                reviewlist: "screens.admin.homework.reviewlist",
                reviewdetails: "screens.admin.homework.reviewdetails",
                unitdetails: "screens.admin.homework.unitdetails",
                unsubmitdetails: "screens.admin.homework.unsubmitdetails",
                assignmentlist: "screens.admin.homework.assignmentlist",
                unitpreview: "screens.admin.homework.unitpreview",
                assignmentpreview: "screens.admin.homework.assignmentpreview",
                assignmentdetails: "screens.admin.homework.assignmentdetails",
                statistic: "screens.admin.homework.statistic",
                management: {
                    list: "screens.admin.homework.management.list",
                    makeuplist: "screens.admin.homework.management.makeuplist",
                    summary: "screens.admin.homework.management.summary",
                    homeworksummary: "screens.admin.homework.management.homeworksummary",
                    reviewlist: "screens.admin.homework.management.reviewlist",
                    review: "screens.admin.homework.management.review",
                },
            },
            icons: "screens.admin.icons",
            language: {
                add: "screens.admin.language.add",
                details: "screens.admin.language.details",
                edit: "screens.admin.language.edit",
                list: "screens.admin.language.list",
            },
            emailverificationcode: {
                list: "screens.admin.emailverificationcode.list",
            },
            phoneverificationcode: {
                list: "screens.admin.phoneverificationcode.list",
            },
            user: {
                manageuser: "screens.admin.user.manageuser",
                manageteacheruser: "screens.admin.user.manageteacheruser",
                manageagentuser: "screens.admin.user.manageagentuser",
                manageotheruser: "screens.admin.user.manageotheruser",
            },
            maps: "screens.admin.maps",
            messageboard: {
                details: "screens.admin.messageboard.details",
                detailslist: "screens.admin.messageboard.detailslist",
                list: "screens.admin.messageboard.list",
            },
            error: {
                details: "screens.admin.error.details",
                list: "screens.admin.error.list",
                dashboard: "screens.admin.error.dashboard",
            },
            apiexceptionrule: {
                add: "screens.admin.apiexceptionrule.add",
                details: "screens.admin.apiexceptionrule.details",
                list: "screens.admin.apiexceptionrule.list",
            },
            partner: {
                list: "screens.admin.partner.list",
                edit: "screens.admin.partner.edit",
                user: {
                    add: "screens.admin.partner.user.add",
                    edit: "screens.admin.partner.user.edit",
                    list: "screens.admin.partner.user.list",
                    changepassword: "screens.admin.partner.user.changepassword",
                },
            },
            product: {
                add: "screens.admin.product.add",
                details: "screens.admin.product.details",
                edit: "screens.admin.product.edit",
                list: "screens.admin.product.list",
            },
            profile: "screens.admin.profile",

            section: {
                add: "screens.admin.section.add",
                details: "screens.admin.section.details",
                edit: "screens.admin.section.edit",
                list: "screens.admin.section.list",
            },
            setting: "screens.admin.setting",
            voicesetting: "screens.admin.voicesetting",
            automarknumber: "screens.admin.automarknumber",
            featureconfiguration: "screens.admin.featureconfiguration",
            redenvelopesetting: "screens.admin.redenvelopesetting",
            chineseparksetting: "screens.admin.chineseparksetting",
            servicesetting: "screens.admin.servicesetting",
            testspeechrecognition: "screens.admin.testspeechrecognition",
            regionserversetting: "screens.admin.regionserversetting",
            currencyInfo: "screens.admin.currencyInfo",
            offlineproblem: "screens.admin.offlineproblem",
            suggestion: {
                edit: "screens.admin.suggestion.edit",
                details: "screens.admin.suggestion.details",
                list: "screens.admin.suggestion.list",
            },
            contact: {
                edit: "screens.admin.contact.edit",
                list: "screens.admin.contact.list",
                details: "screens.admin.contact.details",
            },
            syllable: {
                list: "screens.admin.syllable.list",
            },
            tables: "screens.admin.tables",
            unit: {
                add: "screens.admin.unit.add",
                batchadd: "screens.admin.unit.batchadd",
                simplebatchadd: "screens.admin.unit.simplebatchadd",
                batchexport: "screens.admin.unit.batchexport",
                details: "screens.admin.unit.details",
                edit: "screens.admin.unit.edit",
                move: "screens.admin.unit.move",
                fields: {
                    shared: {
                        a5anda6fields: "screens.admin.unit.fields.shared.a5anda6fields",
                        multiunitfields: "screens.admin.unit.fields.shared.multiunitfields",
                        posc1and2and3fields: "screens.admin.unit.fields.shared.posc1and2and3fields",
                    },
                    types: {
                        anyanswersfield: "screens.admin.unit.fields.types.anyanswersfield",
                        articlefield: "screens.admin.unit.fields.types.articlefield",
                        audiofield: "screens.admin.unit.fields.types.audiofield",
                        charsfield: "screens.admin.unit.fields.types.charsfield",
                        htmlfield: "screens.admin.unit.fields.types.htmlfield",
                        charstextfield: "screens.admin.unit.fields.types.charstextfield",
                        charstokentextfield: "screens.admin.unit.fields.types.charstokentextfield",
                        choicecharsfield: "screens.admin.unit.fields.types.choicecharsfield",
                        choiceimagefield: "screens.admin.unit.fields.types.choiceimagefield",
                        choicelocalizabletextfield: "screens.admin.unit.fields.types.choicelocalizabletextfield",
                        choicetextfield: "screens.admin.unit.fields.types.choicetextfield",
                        contentfield: "screens.admin.unit.fields.types.contentfield",
                        fillingblanklistfield: "screens.admin.unit.fields.types.fillingblanklistfield",
                        generalarticlefield: "screens.admin.unit.fields.types.generalarticlefield",
                        articleselectfield: "screens.admin.unit.fields.types.articleselectfield",
                        layoutmodefield: "screens.admin.unit.fields.types.layoutmodefield",
                        imagefield: "screens.admin.unit.fields.types.imagefield",
                        imagefieldlist: "screens.admin.unit.fields.types.imagefieldlist",
                        imagedatafield: "screens.admin.unit.fields.types.imagedatafield",
                        imagecharsfield: "screens.admin.unit.fields.types.imagecharsfield",
                        localizabletextfield: "screens.admin.unit.fields.types.localizabletextfield",
                        marktexteditor: "screens.admin.unit.fields.types.marktexteditor",
                        personlist: "screens.admin.unit.fields.types.personlist",
                        tablefield: "screens.admin.unit.fields.types.tablefield",
                        texttablefield: "screens.admin.unit.fields.types.texttablefield",
                        marktexttablefield: "screens.admin.unit.fields.types.marktexttablefield",
                        imagetexttablefield: "screens.admin.unit.fields.types.imagetexttablefield",
                        spokendialogfield: "screens.admin.unit.fields.types.spokendialogfield",
                        chinesefield: "screens.admin.unit.fields.types.chinesefield",
                        paragraphfield: "screens.admin.unit.fields.types.paragraphfield",
                        readingdialogfield: "screens.admin.unit.fields.types.readingdialogfield",
                        sentencefield: "screens.admin.unit.fields.types.sentencefield",
                        sentencelocalizabletextfield: "screens.admin.unit.fields.types.sentencelocalizabletextfield",
                        orderwordsfield: "screens.admin.unit.fields.types.orderwordsfield",
                        practicesfield: "screens.admin.unit.fields.types.practicesfield",
                        referenceanswerfield: "screens.admin.unit.fields.types.referenceanswerfield",
                        textsourcefield: "screens.admin.unit.fields.types.textsourcefield",
                        answerfields: "screens.admin.unit.fields.types.answerfields",
                    },
                    a1fields: "screens.admin.unit.fields.a1fields",
                    poza4fields: "screens.admin.unit.fields.poza4fields",
                    sozb1fields: "screens.admin.unit.fields.sozb1fields",
                    pozb2fields: "screens.admin.unit.fields.pozb2fields",
                    pozb3fields: "screens.admin.unit.fields.pozb3fields",
                    sozc1fields: "screens.admin.unit.fields.sozc1fields",
                    cpfields: "screens.admin.unit.fields.cpfields",
                    d1fields: "screens.admin.unit.fields.d1fields",
                    pozd3fields: "screens.admin.unit.fields.pozd3fields",
                    pozd4fields: "screens.admin.unit.fields.pozd4fields",
                    soze1fields: "screens.admin.unit.fields.soze1fields",
                    poze2fields: "screens.admin.unit.fields.poze2fields",
                    poze3fields: "screens.admin.unit.fields.poze3fields",
                    pozf1fields: "screens.admin.unit.fields.pozf1fields",
                    g1fields: "screens.admin.unit.fields.g1fields",
                    h1fields: "screens.admin.unit.fields.h1fields",
                    pozi1fields: "screens.admin.unit.fields.pozi1fields",
                    pozi2fields: "screens.admin.unit.fields.pozi2fields",
                    pozi3fields: "screens.admin.unit.fields.pozi3fields",
                    pozi4fields: "screens.admin.unit.fields.pozi4fields",
                    pozi5fields: "screens.admin.unit.fields.pozi5fields",
                    pozi6fields: "screens.admin.unit.fields.pozi6fields",
                    i7fields: "screens.admin.unit.fields.i7fields",
                    pozi8fields: "screens.admin.unit.fields.pozi8fields",
                    poab1fields: "screens.admin.unit.fields.poab1fields",
                    poab2fields: "screens.admin.unit.fields.poab2fields",
                    poab3fields: "screens.admin.unit.fields.poab3fields",
                    poba1fields: "screens.admin.unit.fields.poba1fields",
                    poba2fields: "screens.admin.unit.fields.poba2fields",
                    pobb1fields: "screens.admin.unit.fields.pobb1fields",
                    pobc1fields: "screens.admin.unit.fields.pobc1fields",
                    pogc1fields: "screens.admin.unit.fields.pogc1fields",
                    poca1fields: "screens.admin.unit.fields.poca1fields",
                    poca2fields: "screens.admin.unit.fields.poca2fields",
                    poca3fields: "screens.admin.unit.fields.poca3fields",
                    poca4fields: "screens.admin.unit.fields.poca4fields",
                    poca5fields: "screens.admin.unit.fields.poca5fields",
                    poca6fields: "screens.admin.unit.fields.poca6fields",
                    pocb1fields: "screens.admin.unit.fields.pocb1fields",
                    pocb2fields: "screens.admin.unit.fields.pocb2fields",
                    pocb3fields: "screens.admin.unit.fields.pocb3fields",
                    pocc1fields: "screens.admin.unit.fields.pocc1fields",
                    pocc2fields: "screens.admin.unit.fields.pocc2fields",
                    pocx1fields: "screens.admin.unit.fields.pocx1fields",
                    pocx2fields: "screens.admin.unit.fields.pocx2fields",
                    pocx3fields: "screens.admin.unit.fields.pocx3fields",
                    poea2fields: "screens.admin.unit.fields.poea2fields",
                    poea3fields: "screens.admin.unit.fields.poea3fields",
                    poea4fields: "screens.admin.unit.fields.poea4fields",
                    poea5fields: "screens.admin.unit.fields.poea5fields",
                    poea6fields: "screens.admin.unit.fields.poea6fields",
                    poeb1fields: "screens.admin.unit.fields.poeb1fields",
                    poeb2fields: "screens.admin.unit.fields.poeb2fields",
                    poeb3fields: "screens.admin.unit.fields.poeb3fields",
                    poeb4fields: "screens.admin.unit.fields.poeb4fields",
                    poex1fields: "screens.admin.unit.fields.poex1fields",
                    poeo1fields: "screens.admin.unit.fields.poeo1fields",
                    psuo1fields: "screens.admin.unit.fields.psuo1fields",
                    poka1fields: "screens.admin.unit.fields.poka1fields",
                    poka2fields: "screens.admin.unit.fields.poka2fields",
                    poka3fields: "screens.admin.unit.fields.poka3fields",
                    poka3simplefields: "screens.admin.unit.fields.poka3simplefields",
                    poka4fields: "screens.admin.unit.fields.poka4fields",
                    poka4simplefields: "screens.admin.unit.fields.poka4simplefields",
                    pobx1fields: "screens.admin.unit.fields.pobx1fields",
                    pobx2fields: "screens.admin.unit.fields.pobx2fields",
                    pola1fields: "screens.admin.unit.fields.pola1fields",
                    pomx1fields: "screens.admin.unit.fields.pomx1fields",
                    pomx2fields: "screens.admin.unit.fields.pomx2fields",
                    pomx3fields: "screens.admin.unit.fields.pomx3fields",
                    pomx4fields: "screens.admin.unit.fields.pomx4fields",
                    posc1fields: "screens.admin.unit.fields.posc1fields",
                    posc2fields: "screens.admin.unit.fields.posc2fields",
                    posc3fields: "screens.admin.unit.fields.posc3fields",
                    psab1fields: "screens.admin.unit.fields.psab1fields",
                    psab4fields: "screens.admin.unit.fields.psab4fields",
                    psac1fields: "screens.admin.unit.fields.psac1fields",
                    psac2fields: "screens.admin.unit.fields.psac2fields",
                    psaf1fields: "screens.admin.unit.fields.psaf1fields",
                    psag1fields: "screens.admin.unit.fields.psag1fields",
                    psah1fields: "screens.admin.unit.fields.psah1fields",
                    psah2fields: "screens.admin.unit.fields.psah2fields",
                    psar1fields: "screens.admin.unit.fields.psar1fields",
                    psba1fields: "screens.admin.unit.fields.psba1fields",
                    psba2fields: "screens.admin.unit.fields.psba2fields",
                    psba3fields: "screens.admin.unit.fields.psba3fields",
                    psba4fields: "screens.admin.unit.fields.psba4fields",
                    psba5fields: "screens.admin.unit.fields.psba5fields",
                    psba6fields: "screens.admin.unit.fields.psba6fields",
                    psbb1fields: "screens.admin.unit.fields.psbb1fields",
                    psbc1fields: "screens.admin.unit.fields.psbc1fields",
                    psbx1fields: "screens.admin.unit.fields.psbx1fields",
                    psbx2fields: "screens.admin.unit.fields.psbx2fields",
                    pscx1fields: "screens.admin.unit.fields.pscx1fields",
                    psda1fields: "screens.admin.unit.fields.psda1fields",
                    psdb1fields: "screens.admin.unit.fields.psdb1fields",
                    psdb2fields: "screens.admin.unit.fields.psdb2fields",
                    psdc1fields: "screens.admin.unit.fields.psdc1fields",
                    psdc2fields: "screens.admin.unit.fields.psdc2fields",
                    psdc3fields: "screens.admin.unit.fields.psdc3fields",
                    psfa1fields: "screens.admin.unit.fields.psfa1fields",
                    psfa2fields: "screens.admin.unit.fields.psfa2fields",
                    pslx1fields: "screens.admin.unit.fields.pslx1fields",
                    pslx2fields: "screens.admin.unit.fields.pslx2fields",
                    pska5fields: "screens.admin.unit.fields.pska5fields",
                    pska5simplefields: "screens.admin.unit.fields.pska5simplefields",
                    pszm1fields: "screens.admin.unit.fields.pszm1fields",
                    psdo1fields: "screens.admin.unit.fields.psdo1fields",
                    pssd2fields: "screens.admin.unit.fields.pssd2fields",
                    pssd2audiofields: "screens.admin.unit.fields.pssd2audiofields",
                    posb1fields: "screens.admin.unit.fields.posb1fields",
                    pssd1fields: "screens.admin.unit.fields.pssd1fields",
                    psso1fields: "screens.admin.unit.fields.psso1fields",
                },
                forms: {
                    a1form: "screens.admin.unit.forms.a1form",
                    a4form: "screens.admin.unit.forms.a4form",
                    b1form: "screens.admin.unit.forms.b1form",
                    b2form: "screens.admin.unit.forms.b2form",
                    cpform: "screens.admin.unit.forms.cpform",
                    unitform: "screens.admin.unit.forms.unitform",
                    simpleunitform: "screens.admin.unit.forms.simpleunitform",
                    simplebatchunitform: "screens.admin.unit.forms.simplebatchunitform",
                    shared: {
                        a5anda6form: "screens.admin.unit.forms.shared.a5anda6form",
                        b4andd2form: "screens.admin.unit.forms.shared.b4andd2form",
                    },
                },
                list: "screens.admin.unit.list",
            },
            unittype: {
                add: "screens.admin.unittype.add",
                details: "screens.admin.unittype.details",
                edit: "screens.admin.unittype.edit",
                list: "screens.admin.unittype.list",
            },
            unittitle: {
                add: "screens.admin.unittitle.add",
                details: "screens.admin.unittitle.details",
                edit: "screens.admin.unittitle.edit",
                list: "screens.admin.unittitle.list",
            },
            book: {
                add: "screens.admin.book.add",
                details: "screens.admin.book.details",
                edit: "screens.admin.book.edit",
                list: "screens.admin.book.list",
                submit: "screens.admin.book.submit",
                addsection: "screens.admin.book.addsection",
                editsection: "screens.admin.book.editsection",
                addrawunit: "screens.admin.book.addrawunit",
                editrawunit: "screens.admin.book.editrawunit",
                deleted: "screens.admin.book.deleted",
            },
            unittaskconfiguration: {
                add: "screens.admin.unittaskconfiguration.add",
                details: "screens.admin.unittaskconfiguration.details",
                edit: "screens.admin.unittaskconfiguration.edit",
                list: "screens.admin.unittaskconfiguration.list",
            },
            oralbook: {
                add: "screens.admin.oralbook.add",
                details: "screens.admin.oralbook.details",
                edit: "screens.admin.oralbook.edit",
                list: "screens.admin.oralbook.list",
                submit: "screens.admin.oralbook.submit",
                addsection: "screens.admin.oralbook.addsection",
                editsection: "screens.admin.oralbook.editsection",
                addunit: "screens.admin.oralbook.addunit",
                editunit: "screens.admin.oralbook.editunit",
                unitlist: "screens.admin.oralbook.unitlist",
            },
            readingbook: {
                add: "screens.admin.readingbook.add",
                details: "screens.admin.readingbook.details",
                edit: "screens.admin.readingbook.edit",
                list: "screens.admin.readingbook.list",
                submit: "screens.admin.readingbook.submit",
                addsection: "screens.admin.readingbook.addsection",
                editsection: "screens.admin.readingbook.editsection",
                addunit: "screens.admin.readingbook.addunit",
                editunit: "screens.admin.readingbook.editunit",
            },
            withdrawal: {
                list: "screens.admin.withdrawal.list",
                details: "screens.admin.withdrawal.details",
                edit: "screens.admin.withdrawal.edit",
            },
            chineseparkserver: {
                add: "screens.admin.chineseparkserver.add",
                edit: "screens.admin.chineseparkserver.edit",
                details: "screens.admin.chineseparkserver.details",
                list: "screens.admin.chineseparkserver.list",
            },
            talkchinese: {
                add: "screens.admin.talkchinese.add",
                edit: "screens.admin.talkchinese.edit",
                details: "screens.admin.talkchinese.details",
                list: "screens.admin.talkchinese.list",
                adddialog: "screens.admin.talkchinese.adddialog",
                editdialog: "screens.admin.talkchinese.editdialog",
                statistic: "screens.admin.talkchinese.statistic",
            },
            chooseschool: "screens.admin.chooseschool",
            manage: "screens.admin.manage",
            other: "screens.admin.other",
            jiaocai: "screens.jiaocai.other",
        },
        portal: {
            home: "screens.portal.home",
            product: "screens.portal.product",
            pricing: "screens.portal.pricing",
            partner: "screens.portal.partner",
            jobs: "screens.portal.jobs",
            undone: "screens.portal.undone",
            contact: "screens.portal.contact",
            download: "screens.portal.download",
            downloadapp: "screens.portal.downloadapp",
            studentuse: "screens.portal.studentuse",
            teacheruse: "screens.portal.teacheruse",
            agent: "screens.portal.agent",
            termscondition: "screens.portal.termscondition",
            privacy: "screens.portal.privacy",
            rewardterms: "screens.portal.rewardterms",
            roomvipterms: "screens.portal.roomvipterms",
            aboutus: "screens.portal.aboutus",
            homework: "screens.portal.homework",
            talkchinese: "screens.portal.talkchinese",
            chinesepark: "screens.portal.chinesepark",
        },
        plain: {
            userhomeworkunit: "screens.plain.userhomeworkunit",
            teachershare: "screens.plain.teachershare",
            studentshare: "screens.plain.studentshare",
            promotiondetails: "screens.plain.promotiondetails",
            joinclass: "screens.plain.joinclass",
            whiteboard: "screens.plain.whiteboard",
        },
        default: "screens.default",
    },
    session: "session",
    taskqueue: "taskqueue",
};
