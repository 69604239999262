import _ from "lodash";
import Constants from "../config/Constants";

const addPrefix = (prefix, obj) => {
    let newObj = {};
    _.each(obj, (item, key) => {
        if (_.isString(item)) {
            newObj[key] = `${prefix}.${item}`;
        } else if (_.isObject(item)) {
            newObj[key] = addPrefix(prefix, item);
        }
    });
    return newObj;
};

let UIDs = {
    navigators: {
        main: "navigators.main",
    },
    navigation: {
        navigators: {
            cclass: {
                mainNavigator: "navigation.navigators.cclass.mainNavigator",
            },
            cplan: {
                mainNavigator: "navigation.navigators.cplan.mainNavigator",
            },
        },
    },
    components: {
        base: {
            errorBoundary: "components.base.errorBoundary",
            loadScreenErrorHandler: "components.base.loadScreenErrorHandler",
        },
        customModule: {
            speechRecognition: "components.customModule.speechRecognition",
        },
        ui: {
            autoHeightWebView: "components.ui.autoHeightWebView",
            reportWordError: "components.ui.reportWordError",
            bottompopexplanation: "components.ui.bottompopexplanation",
            bottomPopInput: "components.ui.bottomPopInput",
            writeInput: "components.ui.writeInput",
            replyInput: "components.ui.replyInput",
            wordAnalysisView: "components.ui.wordAnalysisView",
            commonmodal: "components.ui.commonmodal",
            commonUpgradeModal: "components.ui.commonUpgradeModal",
            speechTestBox: "components.ui.speechTestBox",
            shareBulletinBoard: "components.ui.shareBulletinBoard",
            collapsiblePanel: "components.ui.collapsiblePanel",
            chatView: "components.ui.chatView",
            confirmModal: "components.ui.confirmModal",
            sketchCanvasModal: "components.ui.sketchCanvasModal",
            fileUploadModal: "components.ui.fileUploadModal",
            imagePickerModal: "components.ui.imagePickerModal",
            pyCandidatesBar: "components.ui.pyCandidatesBar",
            zoomerImage: "components.ui.zoomerImage",
            screenloadingindicator: "components.ui.screenloadingindicator",
            unitissuereportmodal: "components.ui.unitissuereportmodal",
        },
        chinesepark: {
            mediaTestModal: "components.chinesepark.mediaTestModal",
            audioTestModal: "components.chinesepark.audioTestModal",
            pkBooksModal: "components.chinesepark.pkBooksModal",
            pkLevelModal: "components.chinesepark.pkLevelModal",
            banChatModal: "components.chinesepark.banChatModal",
            passwordModal: "components.chinesepark.passwordModal",
            inviteModal: "components.chinesepark.inviteModal",
            spokenBooksModal: "components.chinesepark.spokenBooksModal",
            pkResultRankView: "components.chinesepark.pkResultRankView",
            userListView: "components.chinesepark.userListView",
            whiteboard: "components.chinesepark.whiteboard",
            shareScreen: "components.chinesepark.shareScreen",
        },
    },
    screens: {
        account: {
            bindMail: "screens.account.bindMail",
            forgetpassword: "screens.account.forgetpassword",
            home: "screens.account.home",
            login: "screens.account.login",
            register: "screens.account.register",
            verifyMail: "screens.account.verifyMail",
            changepasswordfirstlogin: "screens.account.changepasswordfirstlogin",
        },
        admin: {
            dashboard: "screens.admin.dashboard",
            manageUsers: "screens.admin.manageUsers",
            default: "screens.admin.default",
            userDetails: "screens.admin.userDetails",
        },
        default: "screens.default",
        splash: "screens.splash",
        introductionView: "screens.introductionView",
        example: "screens.example",
        review: {
            completeReview: "screens.review.completeReview",
            default: "screens.review.default",
            review: "screens.review.review",
        },
        conversation: {
            addMember: "screens.conversation.addMember",
            chat: "screens.conversation.chat",
            createConversation: "screens.conversation.createConversation",
            list: "screens.conversation.list",
            groupUserList: "screens.conversation.groupUserList",
        },
        aichat: {
            chat: "screens.aichat.chat",
        },
        me: {
            addReportMail: "screens.me.addReportMail",
            addStudyPlan: "screens.me.addStudyPlan",
            default: "screens.me.default",
            editReportMail: "screens.me.editReportMail",
            feedbackSuggestion: "screens.me.feedbackSuggestion",
            inviteFriend: "screens.me.inviteFriend",
            notification: "screens.me.notification",
            language: "screens.me.language",
            linkEmail: "screens.me.linkEmail",
            parentEmail: "screens.me.parentEmail",
            member: "screens.me.member",
            myRewards: "screens.me.myRewards",
            pointsDetail: "screens.me.pointsDetail",
            profileSetting: "screens.me.profileSetting",
            resetPassword: "screens.me.resetPassword",
            rewardHistory: "screens.me.rewardHistory",
            rewardPlanList: "screens.me.rewardPlanList",
            rewardTask: "screens.me.rewardTask",
            setRewardPlan: "screens.me.setRewardPlan",
            stats: "screens.me.stats",
            studyPlanList: "screens.me.studyPlanList",
            myClass: "screens.me.myClass",
            myWallet: "screens.me.myWallet",
            fundsDetails: "screens.me.fundsDetails",
            withdraw: "screens.me.withdraw",
            bankAccounts: "screens.me.bankAccounts",
            editBankAccount: "screens.me.editBankAccount",
            about: "screens.me.about",
            backgroundTaskOverview: "screens.me.backgroundTaskOverview",
            makePayment: "screens.me.makePayment",
            paymentHistory: "screens.me.paymentHistory",
            webView: "screens.me.webView",
            deleteAccount: "screens.me.deleteAccount",
            serverSetting: "screens.me.serverSetting",
            roomHistory: "screens.me.roomHistory",
            roomHistoryDetails: "screens.me.roomHistoryDetails",
        },
        member: {
            bookList: "screens.member.bookList",
            chineseTest: "screens.member.chineseTest",
            completeCourse: "screens.member.completeCourse",
            default: "screens.member.defaultHome",
            startTest: "screens.member.startTest",
            studyTaskChoice: "screens.member.studyTaskChoice",
            testDecription: "screens.member.testDecription",
            testResult: "screens.member.testResult",
            testUnit: "screens.member.testUnit",
        },
        network: {
            default: "screens.network.default",
        },
        course: {
            details: "screens.course.details",
            list: "screens.course.list",
            section: "screens.course.section",
            unit: "screens.course.unit",
            unitlayout: "screens.course.unitlayout",
            recordsetting: "screens.course.recordsetting",
            units: {
                base: {
                    spokenreadingheader: "screens.course.units.base.spokenreadingheader",
                    referenceanswerview: "screens.course.units.base.referenceanswerview",
                    recordingview: "screens.course.units.base.recordingview",
                    basepracticeunitwithgeneralarticle: "screens.course.units.base.basepracticeunitwithgeneralarticle",
                    basepracticemultiunitwithsharedtop: "screens.course.units.base.basepracticemultiunitwithsharedtop",
                    basearticlepracticesunit: "screens.course.units.base.basearticlepracticesunit",
                },
                basepracticeunit: "screens.course.units.basepracticeunit",
                askanswerunit: "screens.course.units.askanswerunit",
                a1unit: "screens.course.units.a1unit",
                poza4unit: "screens.course.units.poza4unit",
                poza5unit: "screens.course.units.poza5unit",
                poza6unit: "screens.course.units.poza6unit",
                sozb1unit: "screens.course.units.sozb1unit",
                pozb2unit: "screens.course.units.pozb2unit",
                pozb3unit: "screens.course.units.pozb3unit",
                pozb4unit: "screens.course.units.pozb4unit",
                sozc1unit: "screens.course.units.sozc1unit",
                cpunit: "screens.course.units.cpunit",
                d1unit: "screens.course.units.d1unit",
                pozd2unit: "screens.course.units.pozd2unit",
                pozd3unit: "screens.course.units.pozd3unit",
                pozd4unit: "screens.course.units.pozd4unit",
                soze1unit: "screens.course.units.soze1unit",
                poze2unit: "screens.course.units.poze2unit",
                pozf1unit: "screens.course.units.pozf1unit",
                g1unit: "screens.course.units.g1unit",
                h1unit: "screens.course.units.h1unit",
                pozi1unit: "screens.course.units.pozi1unit",
                pozi2unit: "screens.course.units.pozi2unit",
                pozi3unit: "screens.course.units.pozi3unit",
                pozi4unit: "screens.course.units.pozi4unit",
                pozi5unit: "screens.course.units.pozi5unit",
                pozi6unit: "screens.course.units.pozi6unit",
                i7unit: "screens.course.units.i7unit",
                pozi8unit: "screens.course.units.pozi8unit",
                poab1unit: "screens.course.units.poab1unit",
                poab2unit: "screens.course.units.poab2unit",
                poab3unit: "screens.course.units.poab3unit",
                poba1unit: "screens.course.units.poba1unit",
                pobb1unit: "screens.course.units.pobb1unit",
                pobs1unit: "screens.course.units.pobs1unit",
                pobx1unit: "screens.course.units.pobx1unit",
                pobx2unit: "screens.course.units.pobx2unit",
                poca3unit: "screens.course.units.poca3unit",
                poca4unit: "screens.course.units.poca4unit",
                pocc1unit: "screens.course.units.pocc1unit",
                pocx1unit: "screens.course.units.pocx1unit",
                pocx2unit: "screens.course.units.pocx2unit",
                pocx3unit: "screens.course.units.pocx3unit",
                pocx4unit: "screens.course.units.pocx4unit",
                poea5unit: "screens.course.units.poea5unit",
                poea6unit: "screens.course.units.poea6unit",
                poea7unit: "screens.course.units.poea7unit",
                poex1unit: "screens.course.units.poex1unit",
                poeo1unit: "screens.course.units.poeo1unit",
                pomx1unit: "screens.course.units.pomx1unit",
                pomx2unit: "screens.course.units.pomx2unit",
                pomx3unit: "screens.course.units.pomx3unit",
                pomx4unit: "screens.course.units.pomx4unit",
                poka1unit: "screens.course.units.poka1unit",
                poka2unit: "screens.course.units.poka2unit",
                poka3unit: "screens.course.units.poka3unit",
                poka4unit: "screens.course.units.poka4unit",
                pola1unit: "screens.course.units.pola1unit",
                posc1unit: "screens.course.units.posc1unit",
                posc2unit: "screens.course.units.posc2unit",
                posc3unit: "screens.course.units.posc3unit",
                psab2unit: "screens.course.units.psab2unit",
                psab3unit: "screens.course.units.psab3unit",
                psab4unit: "screens.course.units.psab4unit",
                psac1unit: "screens.course.units.psac1unit",
                psac2unit: "screens.course.units.psac2unit",
                psaf1unit: "screens.course.units.psaf1unit",
                psah2unit: "screens.course.units.psah2unit",
                psar1unit: "screens.course.units.psar1unit",
                psba2unit: "screens.course.units.psba2unit",
                psba3unit: "screens.course.units.psba3unit",
                psba4unit: "screens.course.units.psba4unit",
                psbb1unit: "screens.course.units.psbb1unit",
                psbx1unit: "screens.course.units.psbx1unit",
                psbx2unit: "screens.course.units.psbx2unit",
                pscx1unit: "screens.course.units.pscx1unit",
                psda1unit: "screens.course.units.psda1unit",
                psdb1unit: "screens.course.units.psdb1unit",
                psdb2unit: "screens.course.units.psdb2unit",
                psfa1unit: "screens.course.units.psfa1unit",
                psfa2unit: "screens.course.units.psfa2unit",
                pska1unit: "screens.course.units.pska1unit",
                pska5unit: "screens.course.units.pska5unit",
                pslx1unit: "screens.course.units.pslx1unit",
                pslx2unit: "screens.course.units.pslx2unit",
                psuo1unit: "screens.course.units.psuo1unit",
                pszm1unit: "screens.course.units.pszm1unit",
                psdo1unit: "screens.course.units.psdo1unit",
                pssd2unit: "screens.course.units.pssd2unit",
                posb1unit: "screens.course.units.posb1unit",
                pssd1unit: "screens.course.units.pssd1unit",
                psso1unit: "screens.course.units.psso1unit",
                comment: {
                    view: {
                        psdo1commentview: "screens.course.units.comment.view.psdo1commentview",
                        pssd1commentview: "screens.course.units.comment.view.pssd1commentview",
                    },
                    form: {
                        commentform: "screens.course.units.comment.form.commentform",
                        psdo1commentform: "screens.course.units.comment.form.psdo1commentform",
                        pssd1commentform: "screens.course.units.comment.form.pssd1commentform",
                    },
                },
            },
            unitview: "screens.course.unitview",
        },
        homework: {
            default: "screens.homework.default",
            group: "screens.homework.group",
            homework: "screens.homework.homework",
            homeworkcomplete: "screens.homework.homeworkcomplete",
            homeworkhistory: "screens.homework.homeworkhistory",
            homeworkpeerrevise:"screens.homework.homeworkpeerrevise",
            peerrevisedetail:"screens.homework.peerrevisedetail",
            homeworkunit: "screens.homework.homeworkunit",
            homeworkunitredo: "screens.homework.homeworkunitredo",
            homeworkcompleteredo: "screens.homework.homeworkcompleteredo",
            newdefault: "screens.homework.newdefault",
            newlist: "screens.homework.newlist",
            newhistorylist: "screens.homework.newhistorylist",
            reviewlist: "screens.homework.reviewlist",
            replylist: "screens.homework.replylist",
            historypreview: "screens.homework.historypreview",
            classranking: "screens.homework.classranking",
            practiceresult: "screens.homework.practiceresult",
            article: "screens.homework.article",
            resultviews: {
                poka1resultview: "screens.homework.resultviews.poka1resultview",
                poka2resultview: "screens.homework.resultviews.poka2resultview",
                poka3resultview: "screens.homework.resultviews.poka3resultview",
                pska1resultview: "screens.homework.resultviews.pska1resultview",
            },
            reviewunit: "screens.homework.reviewunit",
            completehomework: "screens.homework.completehomework",
            checkunit: "screens.homework.checkunit",
            checkpracticeresult: "screens.homework.checkpracticeresult",
            checkcomplete: "screens.homework.checkcomplete",
            redohomeworkcheckunit: "screens.homework.redohomeworkcheckunit"
        },
        message: {
            default: "screens.message.default",
        },
        chinesepark: {
            default: "screens.chinesepark.default",
            createRoom: "screens.chinesepark.createRoom",
            chooseRegion: "screens.chinesepark.chooseRegion",
            createRoomResult: "screens.chinesepark.createRoomResult",
            room: "screens.chinesepark.room",
            banChat: "screens.chinesepark.banChat",
            blackList: "screens.chinesepark.blackList",
            chmodeSetting: "screens.chinesepark.chmodeSetting",
            noticeSetting: "screens.chinesepark.noticeSetting",
            pkResult: "screens.chinesepark.pkResult",
            passwdSetting: "screens.chinesepark.passwdSetting",
            roomSetting: "screens.chinesepark.roomSetting",
            webView: "screens.chinesepark.webView",
            roomManager: "screens.chinesepark.roomManager",
            vipFeatures: "screens.chinesepark.vipFeatures",
            vipPurchase: "screens.chinesepark.vipPurchase",
            pkRules: "screens.chinesepark.pkRules",
            roommember: "screens.chinesepark.roommember",
            roomList: "screens.chinesepark.roomList",
            shiftstudent:"screens.chinesepark.shiftstudent",
            addroommember:"screens.chinesepark.addroommember",
            editroommember:"screens.chinesepark.editroommember",
            unregstudent: "screens.chinesepark.unregstudent",
        },
        talkchinese: {
            home: "screens.talkchinese.home",
            default: "screens.talkchinese.default",
            practice: "screens.talkchinese.practice",
            practiceresult: "screens.talkchinese.practiceresult",
            settings: "screens.talkchinese.settings",
            level: "screens.talkchinese.level",
            plan: "screens.talkchinese.plan",
            complete: "screens.talkchinese.complete",
            ranking: "screens.talkchinese.ranking",
            reviewlist: "screens.talkchinese.reviewlist",
            historylist: "screens.talkchinese.historylist",
            history: "screens.talkchinese.history",
            historypreview: "screens.talkchinese.historypreview",
        },
        studyletter: {
            default: "screens.studyletter.default",
            taskList: "screens.studyletter.taskList",
            letterList: "screens.studyletter.letterList",
            stateDesc: "screens.studyletter.stateDesc",
            letterDetails: "screens.studyletter.letterDetails",
            wordList: "screens.studyletter.wordList",
            wordDetails: "screens.studyletter.wordDetails",
            practice: "screens.studyletter.practice",
            complete: "screens.studyletter.complete",
            statisticletterList: "screens.studyletter.statisticletterList",
            topList: "screens.studyletter.topList"
        },
        offline: {
            home: "screens.offline.offlineHome",
            homeworkList: "screens.offline.offlineHomeworkList",
            downloadHomework: "screens.offline.offlineDownloadHomework",
            offlineManageHome: "screens.offline.offlineManageHome",
        },
        learn: {
            default: "screens.learn.default",
            search: "screens.learn.search",
            productcourse: "screens.learn.productcourse",
            searchword: "screens.learn.searchword",
            bookdetail: "screens.learn.bookdetail",
            commentlist: "screens.learn.commentlist",
            addcomment: "screens.learn.addcomment",
            practice: "screens.learn.practice",
            complete: "screens.learn.complete",
        },
        bbs: {
            default: "screens.bbs.default",
            search: "screens.bbs.search",
            details: "screens.bbs.details",
            message: "screens.bbs.message",
            myactivity: "screens.bbs.myactivity",
        },
    },
    utils: {
        homework: "utils.homework",
    },
    services: {
        offline: {
            userHomework: {
                baseHomeworkOfflineProcessor: "services.offline.userHomework.baseHomeworkOfflineProcessor",
            },
        },
    },
    shared: {
        errors: "shared.errors",
    },
};

export default addPrefix(Constants.MOBILE_UID_PREFIX, UIDs);
