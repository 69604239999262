import React from "react";

import UIDs from "../UIDs";
import MobileUIDs from "../mobile/UIDs";

//
// register all screens based on layout and path
//

// account layout
// const TeacherLoginScreen = React.lazy(() => import("../screens/account/TeacherLoginScreen"));
// const LoginScreen = React.lazy(() => import("../screens/account/LoginScreen"));
// const UserLoginScreen = React.lazy(() => import("../screens/account/UserLoginScreen"));
// const UserRegisterScreen = React.lazy(() => import("../screens/account/UserRegisterScreen"));
// const ForgotPasswordScreen = React.lazy(() => import("../screens/account/ForgotPasswordScreen"));
// const ChangePasswordScreen = React.lazy(() => import("../screens/account/ChangePasswordScreen"));
// const RegisterScreen = React.lazy(() => import("../screens/account/RegisterScreen"));
// const RegisterPartnerScreen = React.lazy(() => import("../screens/account/RegisterPartnerScreen"));
// const ActiveTeacherScreen = React.lazy(() => import("../screens/account/ActiveTeacherScreen"));

// // admin layout
// const DashboardScreen = React.lazy(() => import("../screens/admin/DashboardScreen"));
// //const ExampleScreen = React.lazy(() => import("../screens/admin/ExampleScreen"));
// //const IconsScreen = React.lazy(() => import("../screens/admin/IconsScreen"));
// //const MapsScreen = React.lazy(() => import("../screens/admin/MapsScreen"));
// const ProfileScreen = React.lazy(() => import("../screens/admin/ProfileScreen"));
// //const TablesScreen = React.lazy(() => import("../screens/admin/TablesScreen"));
// const ManageUserScreen = React.lazy(() => import("../screens/admin/user/ManageUserScreen"));
// const ManageTeacherUserScreen = React.lazy(() => import("../screens/admin/user/ManageTeacherUserScreen"));
// const ManageAgentUserScreen = React.lazy(() => import("../screens/admin/user/ManageAgentUserScreen"));
// const ManageOtherUserScreen = React.lazy(() => import("../screens/admin/user/ManageOtherUserScreen"));
// const ProductListScreen = React.lazy(() => import("../screens/admin/product/ListScreen"));
// const ProductDetailsScreen = React.lazy(() => import("../screens/admin/product/DetailsScreen"));
// const ProductAddScreen = React.lazy(() => import("../screens/admin/product/AddScreen"));
// const ProductEditScreen = React.lazy(() => import("../screens/admin/product/EditScreen"));
// const CourseListScreen = React.lazy(() => import("../screens/admin/course/ListScreen"));
// const CourseDetailsScreen = React.lazy(() => import("../screens/admin/course/DetailsScreen"));
// const CourseEditScreen = React.lazy(() => import("../screens/admin/course/EditScreen"));
// const CourseAddScreen = React.lazy(() => import("../screens/admin/course/AddScreen"));
// const SectionListScreen = React.lazy(() => import("../screens/admin/section/ListScreen"));
// const SectionDetailsScreen = React.lazy(() => import("../screens/admin/section/DetailsScreen"));
// const SectionAddScreen = React.lazy(() => import("../screens/admin/section/AddScreen"));
// const SectionEditScreen = React.lazy(() => import("../screens/admin/section/EditScreen"));
// const ExamListScreen = React.lazy(() => import("../screens/admin/exam/ListScreen"));
// const ExamDetailsScreen = React.lazy(() => import("../screens/admin/exam/DetailsScreen"));
// const ExamAddScreen = React.lazy(() => import("../screens/admin/exam/AddScreen"));
// const ExamEditScreen = React.lazy(() => import("../screens/admin/exam/EditScreen"));
// const UnitTypeListScreen = React.lazy(() => import("../screens/admin/unittype/ListScreen"));
// const UnitTypeDetailsScreen = React.lazy(() => import("../screens/admin/unittype/DetailsScreen"));
// const UnitTypeAddScreen = React.lazy(() => import("../screens/admin/unittype/AddScreen"));
// const UnitTypeEditScreen = React.lazy(() => import("../screens/admin/unittype/EditScreen"));
// const UnitTitleListScreen = React.lazy(() => import("../screens/admin/unittitle/ListScreen"));
// const UnitTitleDetailsScreen = React.lazy(() => import("../screens/admin/unittitle/DetailsScreen"));
// const UnitTitleAddScreen = React.lazy(() => import("../screens/admin/unittitle/AddScreen"));
// const UnitTitleEditScreen = React.lazy(() => import("../screens/admin/unittitle/EditScreen"));
// const LetterListScreen = React.lazy(() => import("../screens/admin/dictionary/letter/ListScreen"));
// const LetterDetailsScreen = React.lazy(() => import("../screens/admin/dictionary/letter/DetailsScreen"));
// const LetterAddScreen = React.lazy(() => import("../screens/admin/dictionary/letter/AddScreen"));
// const LetterEditScreen = React.lazy(() => import("../screens/admin/dictionary/letter/EditScreen"));
// const PinyinListScreen = React.lazy(() => import("../screens/admin/dictionary/pinyin/ListScreen"));
// const PinyinDetailsScreen = React.lazy(() => import("../screens/admin/dictionary/pinyin/DetailsScreen"));
// const PinyinAddScreen = React.lazy(() => import("../screens/admin/dictionary/pinyin/AddScreen"));
// const PinyinEditScreen = React.lazy(() => import("../screens/admin/dictionary/pinyin/EditScreen"));
// const UnitListScreen = React.lazy(() => import("../screens/admin/unit/ListScreen"));
// const UnitDetailsScreen = React.lazy(() => import("../screens/admin/unit/DetailsScreen"));
// const UnitAddScreen = React.lazy(() => import("../screens/admin/unit/AddScreen"));
// const UnitBatchAddScreen = React.lazy(() => import("../screens/admin/unit/BatchAddScreen"));
// const UnitSimpleBatchAddScreen = React.lazy(() => import("../screens/admin/unit/SimpleBatchAddScreen"));
// const UnitBatchExportScreen = React.lazy(() => import("../screens/admin/unit/BatchExportScreen"));
// const UnitEditScreen = React.lazy(() => import("../screens/admin/unit/EditScreen"));
// const UnitMoveScreen = React.lazy(() => import("../screens/admin/unit/MoveScreen"));
// const SyllableListScreen = React.lazy(() => import("../screens/admin/syllable/ListScreen"));
// const SettingScreen = React.lazy(() => import("../screens/admin/SettingScreen"));
// const RedEnvelopeSettingScreen = React.lazy(() => import("../screens/admin/RedEnvelopeSettingScreen"));
// const ChineseParkSettingScreen = React.lazy(() => import("../screens/admin/ChineseParkSettingScreen"));
// const ServiceSettingScreen = React.lazy(() => import("../screens/admin/ServiceSettingScreen"));
// const RegionServerSettingScreen = React.lazy(() => import("../screens/admin/RegionServerSettingScreen"));
// const TestSpeechRecognitionScreen = React.lazy(() => import("../screens/admin/TestSpeechRecognitionScreen"));
// const OfflineProblemScreen = React.lazy(() => import("../screens/admin/OfflineProblemScreen"));
// const FeatureConfigurationScreen = React.lazy(() => import("../screens/admin/FeatureConfigurationScreen"));
// const VoiceSettingScreen = React.lazy(() => import("../screens/admin/VoiceSettingScreen"));
// const CurrencyInfoScreen = React.lazy(() => import("../screens/admin/CurrencyInfoScreen"));
//
// const WordListScreen = React.lazy(() => import("../screens/admin/dictionary/word/ListScreen"));
// const WordDetailsScreen = React.lazy(() => import("../screens/admin/dictionary/word/DetailsScreen"));
// const WordAddScreen = React.lazy(() => import("../screens/admin/dictionary/word/AddScreen"));
// const WordBatchAddScreen = React.lazy(() => import("../screens/admin/dictionary/word/BatchAddScreen"));
// const WordEditScreen = React.lazy(() => import("../screens/admin/dictionary/word/EditScreen"));
//
// const SentenceListScreen = React.lazy(() => import("../screens/admin/dictionary/sentence/ListScreen"));
// const SentenceDetailsScreen = React.lazy(() => import("../screens/admin/dictionary/sentence/DetailsScreen"));
//
// const LanguageListScreen = React.lazy(() => import("../screens/admin/language/ListScreen"));
// const LanguageDetailsScreen = React.lazy(() => import("../screens/admin/language/DetailsScreen"));
// const LanguageAddScreen = React.lazy(() => import("../screens/admin/language/AddScreen"));
// const LanguageEditScreen = React.lazy(() => import("../screens/admin/language/EditScreen"));
// const SuggestionListScreen = React.lazy(() => import("../screens/admin/suggestion/ListScreen"));
// const SuggestionDetailsScreen = React.lazy(() => import("../screens/admin/suggestion/DetailsScreen"));
// //const ClassListScreen = React.lazy(() => import("../screens/admin/class/ListScreen"));
// const ClassListScreen = React.lazy(() => import("../screens/admin/class/ListNewScreen"));
// //const ClassDetailsScreen = React.lazy(() => import("../screens/admin/class/DetailsScreen"));
// const ClassDetailsScreen = React.lazy(() => import("../screens/admin/class/DetailsNewScreen"));
// const ShiftStudentScreen = React.lazy(() => import("../screens/admin/class/ShiftStudentScreen"));
// const UnregStudentScreen = React.lazy(() => import("../screens/admin/class/UnregStudentScreen"));
// const ClassCodeScreen = React.lazy(() => import("../screens/admin/class/CodeScreen"));
// const ClassAddScreen = React.lazy(() => import("../screens/admin/class/AddScreen"));
// const ClassEditScreen = React.lazy(() => import("../screens/admin/class/EditScreen"));
// const ClassHomeworkDetailsScreen = React.lazy(() => import("../screens/admin/class/HomeworkDetailsScreen"));
// //const SIDAddScreen = React.lazy(() => import("../screens/admin/class/student/AddScreen"));
// const SIDAddScreen = React.lazy(() => import("../screens/admin/class/student/AddNewScreen"));
// //const SIDEditScreen = React.lazy(() => import("../screens/admin/class/student/EditScreen"));
// const SIDEditScreen = React.lazy(() => import("../screens/admin/class/student/EditNewScreen"));
// //const SIDImportScreen = React.lazy(() => import("../screens/admin/class/student/ImportScreen"));
// const SIDImportScreen = React.lazy(() => import("../screens/admin/class/student/ImportScreen"));
// const SIDDetailsScreen = React.lazy(() => import("../screens/admin/class/student/DetailsScreen"));
// const SIDChangePasswordScreen = React.lazy(() => import("../screens/admin/class/student/ChangePasswordScreen"));
// const HomeworkListScreen = React.lazy(() => import("../screens/admin/homework/ListScreen"));
// const HomeworkDetailsScreen = React.lazy(() => import("../screens/admin/homework/DetailsScreen"));
// const HomeworkAddUnitScreen = React.lazy(() => import("../screens/admin/homework/AddUnitScreen"));
// const HomeworkAddScreen = React.lazy(() => import("../screens/admin/homework/AddScreen"));
// const HomeworkEditScreen = React.lazy(() => import("../screens/admin/homework/EditScreen"));
// const HomeworkAssignScreen = React.lazy(() => import("../screens/admin/homework/AssignScreen"));
// const HomeworkCommentListScreen = React.lazy(() => import("../screens/admin/homework/CommentListScreen"));
// const HomeworkClassCommentListScreen = React.lazy(() => import("../screens/admin/homework/ClassCommentListScreen"));
// const HomeworkClassReviewListScreen = React.lazy(() => import("../screens/admin/homework/ClassReviewListScreen"));
// const HomeworkCommentDetailsScreen = React.lazy(() => import("../screens/admin/homework/CommentDetailsScreen"));
// const HomeworkReviewDetailsScreen = React.lazy(() => import("../screens/admin/homework/ReviewDetailsScreen"));
// const HomeworkReviewListScreen = React.lazy(() => import("../screens/admin/homework/ReviewListScreen"));
// const HomeworkUnitDetailsScreen = React.lazy(() => import("../screens/admin/homework/UnitDetailsScreen"));
// const AssignmentListScreen = React.lazy(() => import("../screens/admin/homework/AssignmentListScreen"));
// const AssignmentPreviewScreen = React.lazy(() => import("../screens/admin/homework/AssignmentPreviewScreen"));
// const AssignmentDetailsScreen = React.lazy(() => import("../screens/admin/homework/AssignmentDetailsScreen"));
// const UnsubmitDetailsScreen = React.lazy(() => import("../screens/admin/homework/UnsubmitDetailsScreen"));
// const PartnerListScreen = React.lazy(() => import("../screens/admin/partner/ListScreen"));
// const PartnerEditScreen = React.lazy(() => import("../screens/admin/partner/EditScreen"));
// const PartnerUserListScreen = React.lazy(() => import("../screens/admin/partner/user/ListScreen"));
// const PartnerUserAddScreen = React.lazy(() => import("../screens/admin/partner/user/AddScreen"));
// const PartnerUserEditScreen = React.lazy(() => import("../screens/admin/partner/user/EditScreen"));
// const PartnerUserChangePasswordScreen = React.lazy(() => import("../screens/admin/partner/user/ChangePasswordScreen"));
// const PortalHomeScreen = React.lazy(() => import("../screens/portal/HomeScreen"));
// const PortalAgentScreen = React.lazy(() => import("../screens/portal/AgentScreen"));
// const PortalContactScreen = React.lazy(() => import("../screens/portal/ContactScreen"));
// const PortalDownloadScreen = React.lazy(() => import("../screens/portal/DownloadScreen"));
// const PortalDownloadAppScreen = React.lazy(() => import("../screens/portal/DownloadAppScreen"));
// const PortalStudentUseScreen = React.lazy(() => import("../screens/portal/StudentUseScreen"));
// const PortalTeacherUseScreen = React.lazy(() => import("../screens/portal/TeacherUseScreen"));
// const PortalProductScreen = React.lazy(() => import("../screens/portal/ProductScreen"));
// const PortalPricingScreen = React.lazy(() => import("../screens/portal/PricingScreen"));
// const PortalPartnerScreen = React.lazy(() => import("../screens/portal/PartnerScreen"));
// const PortalJobsScreen = React.lazy(() => import("../screens/portal/JobsScreen"));
// const PortalTermsConditionScreen = React.lazy(() => import("../screens/portal/TermsConditionScreen"));
// const PortalPrivacyScreen = React.lazy(() => import("../screens/portal/PrivacyScreen"));
// const PortalAboutUsScreen = React.lazy(() => import("../screens/portal/AboutUsScreen"));
// const PortalHomeworkScreen = React.lazy(() => import("../screens/portal/HomeworkScreen"));
// const PortalTalkChineseScreen = React.lazy(() => import("../screens/portal/TalkChineseScreen"));
// const PortalChineseParkScreen = React.lazy(() => import("../screens/portal/ChineseParkScreen"));
// const PortalRewardTermsScreen = React.lazy(() => import("../screens/portal/RewardTermsScreen"));
// const PortalRoomVipTermsScreen = React.lazy(() => import("../screens/portal/RoomVipTermsScreen"));
//
// const PlainUserHomeworkUnitScreen = React.lazy(() => import("../screens/plain/UserHomeworkUnitScreen"));
// const PlainWhiteboardScreen = React.lazy(() => import("../screens/plain/WhiteboardScreen"));
//
// const RadicalListScreen = React.lazy(() => import("../screens/admin/dictionary/radical/ListScreen"));
// const RadicalDetailsScreen = React.lazy(() => import("../screens/admin/dictionary/radical/DetailsScreen"));
// const RadicalAddScreen = React.lazy(() => import("../screens/admin/dictionary/radical/AddScreen"));
// const RadicalEditScreen = React.lazy(() => import("../screens/admin/dictionary/radical/EditScreen"));
// const BookListScreen = React.lazy(() => import("../screens/admin/book/ListScreen"));
// const BookAddScreen = React.lazy(() => import("../screens/admin/book/AddScreen"));
// const BookEditScreen = React.lazy(() => import("../screens/admin/book/EditScreen"));
// const BookDetailsScreen = React.lazy(() => import("../screens/admin/book/DetailsScreen"));
// const BookSubmitScreen = React.lazy(() => import("../screens/admin/book/SubmitScreen"));
// const BookAddSectionScreen = React.lazy(() => import("../screens/admin/book/AddSectionScreen"));
// const BookEditSectionScreen = React.lazy(() => import("../screens/admin/book/EditSectionScreen"));
// const BookAddRawUnitcreen = React.lazy(() => import("../screens/admin/book/AddRawUnitScreen"));
// const BookEditRawUnitcreen = React.lazy(() => import("../screens/admin/book/EditRawUnitScreen"));
// const BookDeletedScreen = React.lazy(() => import("../screens/admin/book/DeletedScreen"));
//
// const BookAssignmentListScreen = React.lazy(() => import("../screens/admin/bookassignment/ListScreen"));
// const BookAssignmentAddScreen = React.lazy(() => import("../screens/admin/bookassignment/AddScreen"));
// const BookAssignmentEditScreen = React.lazy(() => import("../screens/admin/bookassignment/EditScreen"));
// const BookAssignmentDetailsScreen = React.lazy(() => import("../screens/admin/bookassignment/DetailsScreen"));
//
// const BookgroupListScreen = React.lazy(() => import("../screens/admin/bookgroup/ListScreen"));
// const BookgroupDetailsScreen = React.lazy(() => import("../screens/admin/bookgroup/DetailsScreen"));
// const BookgroupEditScreen = React.lazy(() => import("../screens/admin/bookgroup/EditScreen"));
// const ContactEditScreen = React.lazy(() => import("../screens/admin/contact/EditScreen"));
// const ContactListScreen = React.lazy(() => import("../screens/admin/contact/ListScreen"));
// const ContactDetailsScreen = React.lazy(() => import("../screens/admin/contact/DetailsScreen"));
// const UnitTaskListScreen = React.lazy(() => import("../screens/admin/unittask/ListScreen"));
// const UnitTaskDetailsListScreen = React.lazy(() => import("../screens/admin/unittask/DetailsListScreen"));
// const MakingPictureScreen = React.lazy(() => import("../screens/admin/unittask/MakingPictureScreen"));
// const PublishingListScreen = React.lazy(() => import("../screens/admin/unittask/PublishingListScreen"));
// const VerificationScreen = React.lazy(() => import("../screens/admin/unittask/VerificationScreen"));
// const UnitTaskTemplateConfirmationScreen = React.lazy(() => import("../screens/admin/unittask/TemplateConfirmationScreen"));
// const UnitTaskTemplateValidationScreen = React.lazy(() => import("../screens/admin/unittask/TemplateValidationScreen"));
// const UnitTaskPrototypeDesignScreen = React.lazy(() => import("../screens/admin/unittask/PrototypeDesignScreen"));
// const UnitTaskTemplateDevelopmentScreen = React.lazy(() => import("../screens/admin/unittask/TemplateDevelopmentScreen"));
// const UnitTaskPictureValidationScreen = React.lazy(() => import("../screens/admin/unittask/PictureValidationScreen"));
// const UnitTaskTestContentScreen = React.lazy(() => import("../screens/admin/unittask/TestContentScreen"));
// const UnitTaskFeedbackModificationScreen = React.lazy(() => import("../screens/admin/unittask/FeedbackModificationScreen"));
// const UnitTaskConfigurationListScreen = React.lazy(() => import("../screens/admin/unittaskconfiguration/ListScreen"));
// const UnitTaskConfigurationAddScreen = React.lazy(() => import("../screens/admin/unittaskconfiguration/AddScreen"));
// const UnitTaskConfigurationEditScreen = React.lazy(() => import("../screens/admin/unittaskconfiguration/EditScreen"));
// const OralBookListScreen = React.lazy(() => import("../screens/admin/oralbook/ListScreen"));
// const OralBookAddScreen = React.lazy(() => import("../screens/admin/oralbook/AddScreen"));
// const OralBookEditScreen = React.lazy(() => import("../screens/admin/oralbook/EditScreen"));
// const OralBookDetailsScreen = React.lazy(() => import("../screens/admin/oralbook/DetailsScreen"));
// const OralBookSubmitScreen = React.lazy(() => import("../screens/admin/oralbook/SubmitScreen"));
// const OralBookAddSectionScreen = React.lazy(() => import("../screens/admin/oralbook/AddSectionScreen"));
// const OralBookEditSectionScreen = React.lazy(() => import("../screens/admin/oralbook/EditSectionScreen"));
// const OralBookAddUnitcreen = React.lazy(() => import("../screens/admin/oralbook/AddUnitScreen"));
// const OralBookEditUnitcreen = React.lazy(() => import("../screens/admin/oralbook/EditUnitScreen"));
// const OralBookUnitListScreen = React.lazy(() => import("../screens/admin/oralbook/UnitListScreen"));
// const ReadingBookListScreen = React.lazy(() => import("../screens/admin/readingbook/ListScreen"));
// const ReadingBookAddScreen = React.lazy(() => import("../screens/admin/readingbook/AddScreen"));
// const ReadingBookEditScreen = React.lazy(() => import("../screens/admin/readingbook/EditScreen"));
// const ReadingBookDetailsScreen = React.lazy(() => import("../screens/admin/readingbook/DetailsScreen"));
// const ReadingBookSubmitScreen = React.lazy(() => import("../screens/admin/readingbook/SubmitScreen"));
// const ReadingBookAddSectionScreen = React.lazy(() => import("../screens/admin/readingbook/AddSectionScreen"));
// const ReadingBookEditSectionScreen = React.lazy(() => import("../screens/admin/readingbook/EditSectionScreen"));
// const ReadingBookAddUnitcreen = React.lazy(() => import("../screens/admin/readingbook/AddUnitScreen"));
// const ReadingBookEditUnitcreen = React.lazy(() => import("../screens/admin/readingbook/EditUnitScreen"));
// const HomeworkManagementListScreen = React.lazy(() => import("../screens/admin/homework/management/ListScreen"));
// const HomeworkManagementMakeupListScreen = React.lazy(() => import("../screens/admin/homework/management/MakeupListScreen"));
// const HomeworkManagementSummaryScreen = React.lazy(() => import("../screens/admin/homework/management/SummaryScreen"));
// const HomeworkManagementReviewListScreen = React.lazy(() => import("../screens/admin/homework/management/ReviewListScreen"));
// const HomeworkManagementReviewScreen = React.lazy(() => import("../screens/admin/homework/management/ReviewScreen"));
// const HomeworkManagementHomeworkSummaryScreen = React.lazy(() => import("../screens/admin/homework/management/HomeworkSummaryScreen"));
//
// const AgentManagementListScreen = React.lazy(() => import("../screens/admin/agent/management/ListScreen"));
// const AgentManagementDetailsScreen = React.lazy(() => import("../screens/admin/agent/management/DetailsScreen"));
// const AgentManagementAddScreen = React.lazy(() => import("../screens/admin/agent/management/AddScreen"));
// const AgentManagementEditScreen = React.lazy(() => import("../screens/admin/agent/management/EditScreen"));
// const AgentManagementCodeScreen = React.lazy(() => import("../screens/admin/agent/management/CodeScreen"));
// const WithdrawalScreen = React.lazy(() => import("../screens/admin/agent/WithdrawalScreen"));
// const BankCardDetailsScreen = React.lazy(() => import("../screens/admin/agent/bankcard/DetailsScreen"));
// const BankCardAddScreen = React.lazy(() => import("../screens/admin/agent/bankcard/AddScreen"));
// const BankCardEditScreen = React.lazy(() => import("../screens/admin/agent/bankcard/EditScreen"));
// const WalletScreen = React.lazy(() => import("../screens/admin/agent/WalletScreen"));
// const RedEnvelopeScreen = React.lazy(() => import("../screens/admin/agent/RedEnvelopeScreen"));
// const WithdrawalCompleteScreen = React.lazy(() => import("../screens/admin/agent/WithdrawalCompleteScreen"));
// const SubagentListScreen = React.lazy(() => import("../screens/admin/agent/subagent/ListScreen"));
// const SubagentAddScreen = React.lazy(() => import("../screens/admin/agent/subagent/AddScreen"));
// const SubagentEditScreen = React.lazy(() => import("../screens/admin/agent/subagent/EditScreen"));
// const SubagentCodeScreen = React.lazy(() => import("../screens/admin/agent/subagent/CodeScreen"));
// const SubagentDetailsScreen = React.lazy(() => import("../screens/admin/agent/subagent/DetailsScreen"));
// const TeacherAccountListScreen = React.lazy(() => import("../screens/admin/agent/teacheraccount/ListScreen"));
// const TeacherAccountAddScreen = React.lazy(() => import("../screens/admin/agent/teacheraccount/AddScreen"));
// const TeacherAccountEditScreen = React.lazy(() => import("../screens/admin/agent/teacheraccount/EditScreen"));
// const TeacherAccountChangePasswordScreen = React.lazy(() => import("../screens/admin/agent/teacheraccount/ChangePasswordScreen"));
// const TeacherAccountCodeScreen = React.lazy(() => import("../screens/admin/agent/teacheraccount/CodeScreen"));
// const UserAccountListScreen = React.lazy(() => import("../screens/admin/agent/useraccount/ListScreen"));
// const UserAccountAddScreen = React.lazy(() => import("../screens/admin/agent/useraccount/AddScreen"));
// const UserAccountEditScreen = React.lazy(() => import("../screens/admin/agent/useraccount/EditScreen"));
// const UserAccountCodeScreen = React.lazy(() => import("../screens/admin/agent/useraccount/CodeScreen"));
// const UserAccountChangePasswordScreen = React.lazy(() => import("../screens/admin/agent/useraccount/ChangePasswordScreen"));
// const MessageBoardListScreen = React.lazy(() => import("../screens/admin/messageboard/ListScreen"));
// const MessageBoardDetailsListScreen = React.lazy(() => import("../screens/admin/messageboard/DetailsListScreen"));
// const MessageBoardDetailsScreen = React.lazy(() => import("../screens/admin/messageboard/DetailsScreen"));
// const ErrorListScreen = React.lazy(() => import("../screens/admin/error/ListScreen"));
// const ErrorDetailsScreen = React.lazy(() => import("../screens/admin/error/DetailsScreen"));
// const ErrorDashboardScreen = React.lazy(() => import("../screens/admin/error/DashboardScreen"));
// const AgentStatisticScreen = React.lazy(() => import("../screens/admin/agent/StatisticScreen"));
// const ManagementStatisticScreen = React.lazy(() => import("../screens/admin/agent/management/StatisticScreen"));
// const WithdrawalListScreen = React.lazy(() => import("../screens/admin/agent/management/WithdrawalListScreen"));
// const WithdrawalDetailsScreen = React.lazy(() => import("../screens/admin/agent/management/WithdrawalDetailsScreen"));
// const IncomeStatisticScreen = React.lazy(() => import("../screens/admin/agent/management/IncomeStatisticScreen"));
// const StudentCapitalflowScreen = React.lazy(() => import("../screens/admin/agent/StudentCapitalflowScreen"));
// const AgentReferralCodeScreen = React.lazy(() => import("../screens/admin/agent/AgentReferralCodeScreen"));
// //const AgentApplicationListScreen = React.lazy(() => import("../screens/admin/agent/management/AgentApplicationListScreen"));
// const AgentApplicationDetailsScreen = React.lazy(() => import("../screens/admin/agent/management/AgentApplicationDetailsScreen"));
// const ExceptionEndpointAddScreen = React.lazy(() => import("../screens/admin/apiexceptionrule/AddScreen"));
// const ExceptionEndpointDetailsScreen = React.lazy(() => import("../screens/admin/apiexceptionrule/DetailsScreen"));
// const ExceptionEndpointListScreen = React.lazy(() => import("../screens/admin/apiexceptionrule/ListScreen"));
// const ChineseParkServerAddScreen = React.lazy(() => import("../screens/admin/chineseparkserver/AddScreen"));
// const ChineseParkServerEditScreen = React.lazy(() => import("../screens/admin/chineseparkserver/EditScreen"));
// const ChineseParkServerDetailsScreen = React.lazy(() => import("../screens/admin/chineseparkserver/DetailsScreen"));
// const ChineseParkServerListScreen = React.lazy(() => import("../screens/admin/chineseparkserver/ListScreen"));
// const TalkChineseAddScreen = React.lazy(() => import("../screens/admin/talkchinese/AddScreen"));
// const TalkChineseEditScreen = React.lazy(() => import("../screens/admin/talkchinese/EditScreen"));
// const TalkChineseDetailsScreen = React.lazy(() => import("../screens/admin/talkchinese/DetailsScreen"));
// const TalkChineseListScreen = React.lazy(() => import("../screens/admin/talkchinese/ListScreen"));
// const TalkChineseAddDialogScreen = React.lazy(() => import("../screens/admin/talkchinese/AddDialogScreen"));
// const TalkChineseEditDialogScreen = React.lazy(() => import("../screens/admin/talkchinese/EditDialogScreen"));
// const TalkChineseStatisticScreen = React.lazy(() => import("../screens/admin/talkchinese/StatisticScreen"));
// const EmailVerificationCodeListScreen = React.lazy(() => import("../screens/admin/emailverificationcode/ListScreen"));
// const PhoneVerificationCodeListScreen = React.lazy(() => import("../screens/admin/phoneverificationcode/ListScreen"));
//
// const PromotionManagementListScreen = React.lazy(() => import("../screens/admin/promotion/management/ListScreen"));
// const PromotionManagementAddScreen = React.lazy(() => import("../screens/admin/promotion/management/AddScreen"));
// const PromotionManagementEditScreen = React.lazy(() => import("../screens/admin/promotion/management/EditScreen"));
// const PromotionManagementDetailsScreen = React.lazy(() => import("../screens/admin/promotion/management/DetailsScreen"));
// const PromotionManagementShareAgentListScreen = React.lazy(() => import("../screens/admin/promotion/management/ShareAgentListScreen"));
// const PromotionManagementShareAgentAddScreen = React.lazy(() => import("../screens/admin/promotion/management/ShareAgentAddScreen"));
// const PromotionManagementStatisticScreen = React.lazy(() => import("../screens/admin/promotion/management/StatisticScreen"));
// const PromotionStatisticScreen = React.lazy(() => import("../screens/admin/promotion/StatisticScreen"));
// const PromotionListScreen = React.lazy(() => import("../screens/admin/promotion/PromotionListScreen"));
// const PromotionShareStatisticScreen = React.lazy(() => import("../screens/admin/promotion/ShareStatisticScreen"));
// const PromotionRedEnvelopeStatisticScreen = React.lazy(() => import("../screens/admin/promotion/RedEnvelopeStatisticScreen"));
// const PlainTeacherShareScreen = React.lazy(() => import("../screens/plain/TeacherShareScreen"));
// const PlainStudentShareScreen = React.lazy(() => import("../screens/plain/StudentShareScreen"));
// const PlainPromotionDetailsScreen = React.lazy(() => import("../screens/plain/PromotionDetailsScreen"));
// const PlainJoinClassScreen = React.lazy(() => import("../screens/plain/JoinClassScreen"));
// const ChooseSchoolScreen = React.lazy(() => import("../screens/admin/ChooseSchoolScreen"));
//
// //mobile
// const NewDefaultScreen = React.lazy(() => import("../mobile/screens/homework/NewDefaultScreen"));
// const MobileLoginScreen = React.lazy(() => import("../mobile/screens/account/LoginScreen"));
// const NewListScreen = React.lazy(() => import("../mobile/screens/homework/NewListScreen"));
// const HomeworkUnitScreen = React.lazy(() => import("../mobile/screens/homework/HomeworkUnitScreen"));
// const HomeworkCompleteScreen = React.lazy(() => import("../mobile/screens/homework/HomeworkCompleteScreen"));
// const CompleteHomeworkScreen = React.lazy(() => import("../mobile/screens/homework/CompleteHomeworkScreen"));
// const HomeworkHistoryScreen = React.lazy(() => import("../mobile/screens/homework/HomeworkHistoryScreen"));
// const HomeworkPeerReviseScreen = React.lazy(() => import("../mobile/screens/homework/HomeworkPeerReviseScreen"));
// const PeerReviseDetailScreen = React.lazy(() => import("../mobile/screens/homework/PeerReviseDetailScreen"));
// const ReplyListScreen = React.lazy(() => import("../mobile/screens/homework/ReplyListScreen"));
// const PracticeResultScreen = React.lazy(() => import("../mobile/screens/homework/PracticeResultScreen"));
// const ReviewListScreen = React.lazy(() => import("../mobile/screens/homework/ReviewListScreen"));
// const ReviewUnitScreen = React.lazy(() => import("../mobile/screens/homework/ReviewUnitScreen"));
// const CheckUnitScreen = React.lazy(() => import("../mobile/screens/homework/CheckUnitScreen"));
// const CheckCompleteScreen = React.lazy(() => import("../mobile/screens/homework/CheckCompleteScreen"));
// const ArticleScreen = React.lazy(() => import("../mobile/screens/homework/ArticleScreen"));
// const HistoryPreviewScreen = React.lazy(() => import("../mobile/screens/homework/HistoryPreviewScreen"));
//
// //mobile talk chinese
// const TalkChineseHomeScreen = React.lazy(() => import("../mobile/screens/talkchinese/HomeScreen"));
// const TalkChineseReviewListScreen = React.lazy(() => import("../mobile/screens/talkchinese/ReviewListScreen"));
// const TalkChineseHistoryListScreen = React.lazy(() => import("../mobile/screens/talkchinese/HistoryListScreen"));
// const TalkChineseHistoryScreen = React.lazy(() => import("../mobile/screens/talkchinese/HistoryScreen"));
// const TalkChineseHistoryPreviewScreen = React.lazy(() => import("../mobile/screens/talkchinese/HistoryPreviewScreen"));
// const TalkChineseRankingScreen = React.lazy(() => import("../mobile/screens/talkchinese/RankingScreen"));
// const TalkChineseDefaultScreen = React.lazy(() => import("../mobile/screens/talkchinese/DefaultScreen"));
// const TalkChinesePlanScreen = React.lazy(() => import("../mobile/screens/talkchinese/PlanScreen"));
// const TalkChinesePracticeResultScreen = React.lazy(() => import("../mobile/screens/talkchinese/PracticeResultScreen"));
// const TalkChineseCompleteScreen = React.lazy(() => import("../mobile/screens/talkchinese/CompleteScreen"));
// const TalkChineseLevelScreen = React.lazy(() => import("../mobile/screens/talkchinese/LevelScreen"));
// const TalkChinesePracticeScreen = React.lazy(() => import("../mobile/screens/talkchinese/PracticeScreen"));
// const TalkChineseSettingsScreen = React.lazy(() => import("../mobile/screens/talkchinese/SettingsScreen"));
// const ParentEmailScreen = React.lazy(() => import("../mobile/screens/me/ParentEmailScreen"));
//
// const ChineseparkDefaultScreen = React.lazy(() => import("../mobile/screens/chinesepark/DefaultScreen"));
// const ChineseparkRoomListScreen = React.lazy(() => import("../mobile/screens/chinesepark/RoomListScreen"));
// const ChineseparkRoomSettingScreen = React.lazy(() => import("../mobile/screens/chinesepark/RoomSettingScreen"));
// const ChineseparkRoomManagerScreen = React.lazy(() => import("../mobile/screens/chinesepark/RoomManagerScreen"));
// const ChineseparkRoomBlackListScreen = React.lazy(() => import("../mobile/screens/chinesepark/BlackListScreen"));
// const ChineseparkVipFeaturesScreen = React.lazy(() => import("../mobile/screens/chinesepark/VipFeaturesScreen"));
// const ChineseparkCreateRoomScreen = React.lazy(() => import("../mobile/screens/chinesepark/CreateRoomScreen"));
// const ChineseparkPkRulesScreen = React.lazy(() => import("../mobile/screens/chinesepark/PKRulesScreen"));
// const ChineseparkRoomMemberScreen = React.lazy(() => import("../mobile/screens/chinesepark/RoomMemberScreen"));
// const ChineseparkUnregStudentScreen = React.lazy(() => import("../mobile/screens/chinesepark/UnregStudentScreen"));
// const ChineseparkAddRoomMemberScreen = React.lazy(() => import("../mobile/screens/chinesepark/AddRoomMemberScreen"));
// const ChineseparkEditRoomMemberScreen = React.lazy(() => import("../mobile/screens/chinesepark/EditRoomMemberScreen"));
// const ChineseparkShiftStudentScreen = React.lazy(() => import("../mobile/screens/chinesepark/ShiftStudentScreen"));
//
// const ChineseParkRoomScreen = React.lazy(() => import("../mobile/screens/chinesepark/RoomScreen"));
//
// //mobile learn
// const MobileLearnDefaultScreen = React.lazy(() => import("../mobile/screens/learn/DefaultScreen"));
// const MobileSearchCourseScreen = React.lazy(() => import("../mobile/screens/learn/SearchScreen"));
// const MobileSearchWordScreen = React.lazy(() => import("../mobile/screens/learn/SearchWordScreen"));
// const MobileBookDetailScreen = React.lazy(() => import("../mobile/screens/learn/BookDetailScreen"));
// const MobileCommentListScreen = React.lazy(() => import("../mobile/screens/learn/CommentListScreen"));
// const MobileAddCommentScreen = React.lazy(() => import("../mobile/screens/learn/AddCommentScreen"));
// const MobileLearnPracticeScreen = React.lazy(() => import("../mobile/screens/learn/PracticeScreen"));
// const MobileLearnCompleteScreen = React.lazy(() => import("../mobile/screens/learn/CompleteScreen"));
//
// //mobile study letter
// const StudyLetterDefaultScreen = React.lazy(() => import("../mobile/screens/studyletter/DefaultScreen"));
// const StudyLetterTaskListScreen = React.lazy(() => import("../mobile/screens/studyletter/TaskListScreen"));
// const StudyLetterLetterListScreen = React.lazy(() => import("../mobile/screens/studyletter/LetterListScreen"));
// const StudyLetterStateDescScreen = React.lazy(() => import("../mobile/screens/studyletter/StateDescScreen"));
// const StudyLetterLetterDetailsScreen = React.lazy(() => import("../mobile/screens/studyletter/LetterDetailsScreen"));
// const StudyLetterWordListScreen = React.lazy(() => import("../mobile/screens/studyletter/WordListScreen"));
// const StudyLetterWordDetailsScreen = React.lazy(() => import("../mobile/screens/studyletter/WordDetailsScreen"));
// const StudyLetterPracticeScreen = React.lazy(() => import("../mobile/screens/studyletter/PracticeScreen"));
// const StudyLetterCompleteScreen = React.lazy(() => import("../mobile/screens/studyletter/CompleteScreen"));
// const StudyLetterPracticeResultScreen = React.lazy(() => import("../mobile/screens/studyletter/PracticeResultScreen"));
// const StudyLetterStatisticLetterListScreen = React.lazy(() => import("../mobile/screens/studyletter/StatisticLetterListScreen"));
// const StudyLetterTopListScreen = React.lazy(() => import("../mobile/screens/studyletter/TopListScreen"));
//
// //mobile me
// const MobileMeDefaultScreen = React.lazy(() => import("../mobile/screens/me/DefaultScreen"));
// const MobileMeProfileSettingScreen = React.lazy(() => import("../mobile/screens/me/ProfileSettingScreen"));
// const MobileMeStudyPlanListScreen = React.lazy(() => import("../mobile/screens/me/StudyPlanListScreen"));
// const MobileMeRewardPlanListScreen = React.lazy(() => import("../mobile/screens/me/RewardPlanListScreen"));
// const MobileMeFeedbackSuggestionScreen = React.lazy(() => import("../mobile/screens/me/FeedbackSuggestionScreen"));
// const MobileMeMemberScreen = React.lazy(() => import("../mobile/screens/me/MemberScreen"));
// const MobileMeInviteFriendScreen = React.lazy(() => import("../mobile/screens/me/InviteFriendScreen"));
// const MobileMeMyClassScreen = React.lazy(() => import("../mobile/screens/me/MyClassScreen"));
// const MobileMeMyWalletScreen = React.lazy(() => import("../mobile/screens/me/MyWalletScreen"));
// const MobileMePaymentHistoryScreen = React.lazy(() => import("../mobile/screens/me/PaymentHistoryScreen"));
// const MobileMeNotificationScreen = React.lazy(() => import("../mobile/screens/me/NotificationScreen"));
// const MobileMeLanguageScreen = React.lazy(() => import("../mobile/screens/me/LanguageScreen"));
// const MobileMeAboutScreen = React.lazy(() => import("../mobile/screens/me/AboutScreen"));
// const MobileMeDeleteAccountScreen = React.lazy(() => import("../mobile/screens/me/DeleteAccountScreen"));
// const MobileMeServerSettingScreen = React.lazy(() => import("../mobile/screens/me/ServerSettingScreen"));
// const MobileMeRecordsettingScreen = React.lazy(() => import("../mobile/screens/course/RecordSettingScreen"));
// const MobileMeResetPasswordScreen = React.lazy(() => import("../mobile/screens/me/ResetPasswordScreen"));
// const MobileMeLinkEmailScreen = React.lazy(() => import("../mobile/screens/me/LinkEmailScreen"));
// const MobileMeParentEmailScreenScreen = React.lazy(() => import("../mobile/screens/me/ParentEmailScreen"));
// const MobileMeMakePaymentScreen = React.lazy(() => import("../mobile/screens/me/MakePaymentScreen"));
// const MobileMeFundsDetailsScreen = React.lazy(() => import("../mobile/screens/me/FundsDetailsScreen"));
// const MobileMeWithdrawScreen = React.lazy(() => import("../mobile/screens/me/WithdrawScreen"));
// const MobileMeBankAccountScreen = React.lazy(() => import("../mobile/screens/me/BankAccountsScreen"));
// const MobileMeEditBankAccountScreen = React.lazy(() => import("../mobile/screens/me/EditBankAccountScreen"));
// const MobileMeRoomHistoryScreen = React.lazy(() => import("../mobile/screens/me/RoomHistoryScreen"));
// const MobileMeRoomHistoryDetailsScreen = React.lazy(() => import("../mobile/screens/me/RoomHistoryDetailsScreen"));

//作文
// const CompositionAssignmentListScreen = React.lazy(() => import("../screens/admin/composition/AssignmentListScreen"));
// const CompositionAssignmentPreviewScreen = React.lazy(() => import("../screens/admin/composition/AssignmentPreviewScreen"));
// const CompositionAssignmentDetailsScreen = React.lazy(() => import("../screens/admin/composition/AssignmentDetailsScreen"));
//
// const CompositionManagementListScreen = React.lazy(() => import("../screens/admin/composition/management/ListScreen"));
// const CompositionManagementReviewScreen = React.lazy(() => import("../screens/admin/composition/management/ReviewScreen"));
// const CompositionManagementSummaryScreen = React.lazy(() => import("../screens/admin/composition/management/SummaryScreen"));


var Routes = [
    {
        path: "/account",
        uid: UIDs.layouts.account,
        icon: "",
        component: "null",
        layout: "",
        name: "account",
        grade: "",
    },
    {
        path: "/login",
        uid: UIDs.screens.account.login,
        icon: "",
        component: "LoginScreen",
        layout: "/account",
        name: "login",
        grade: "",
    },
    {
        path: "/agentlogin",
        uid: UIDs.screens.account.agent,
        icon: "",
        component: "TeacherLoginScreen",
        layout: "/account",
        name: "agentLogin",
        grade: "",
    },
    {
        path: "/teacherlogin",
        uid: UIDs.screens.account.teacherlogin,
        icon: "",
        component: "TeacherLoginScreen",
        layout: "/account",
        name: "teacherlogin",
        grade: "",
    },
    {
        path: "/userlogin",
        uid: UIDs.screens.account.userlogin,
        icon: "",
        component: "UserLoginScreen",
        layout: "/account",
        name: "userlogin",
        grade: "",
    },
    {
        path: "/userregister",
        uid: UIDs.screens.account.userregister,
        icon: "",
        component: "UserRegisterScreen",
        layout: "/account",
        name: "userregister",
        grade: "",
    },
    {
        path: "/activeteacher",
        uid: UIDs.screens.account.activeteacher,
        icon: "",
        component: "ActiveTeacherScreen",
        layout: "/account",
        name: "activeteacher",
        grade: "",
    },
    {
        path: "/forgotpassword",
        uid: UIDs.screens.account.forgotpassword,
        component: "ForgotPasswordScreen",
        layout: "/account",
        name: "forgotPassword",
        grade: "",
    },
    {
        path: "/:id/changepassword",
        uid: UIDs.screens.account.changepassword,
        component: "ChangePasswordScreen",
        layout: "/account",
        name: "changePassword",
        grade: "",
    },
    {
        path: "/register",
        uid: UIDs.screens.account.register,
        icon: "",
        component: "RegisterScreen",
        layout: "/account",
        name: "register",
        grade: "",
    },
    {
        path: "/:referralcode/registerpartner",
        uid: UIDs.screens.account.registerpartner,
        icon: "",
        component: "RegisterPartnerScreen",
        layout: "/account",
        name: "partnerRequest",
        grade: "",
    },
    {
        path: "/registerpartner",
        uid: UIDs.screens.account.registerpartner,
        icon: "",
        component: "RegisterPartnerScreen",
        layout: "/account",
        name: "partnerRequest",
        grade: "",
    },
    {
        path: "/admin",
        uid: UIDs.layouts.admin,
        icon: "",
        component: "null",
        layout: "",
        name: "admin",
        grade: "",
    },
    {
        path: "/dashboard",
        uid: UIDs.screens.admin.dashboard,
        icon: "",
        component: "DashboardScreen",
        layout: "/admin",
        name: "dashboard",
        grade: "1",
    },
    {
        path: "/chooseschool",
        uid: UIDs.screens.admin.chooseschool,
        component: "ChooseSchoolScreen",
        layout: "/admin",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.user.manageuser,
        icon: "",
        component: "null",
        layout: "/admin",
        name: "user",
        grade: "1",
    },
    {
        path: "/user/manageuser",
        uid: UIDs.screens.admin.user.manageuser,
        component: "ManageUserScreen",
        icon: "iconfont icon-xiezuo",
        layout: "/admin",
        name: "studentuser",
    },
    {
        path: "/user/manageteacheruser",
        uid: UIDs.screens.admin.user.manageteacheruser,
        component: "ManageTeacherUserScreen",
        icon: "iconfont icon-laoshi",
        layout: "/admin",
        name: "teacheruser",
    },
    {
        path: "/user/manageagentuser",
        uid: UIDs.screens.admin.user.manageagentuser,
        component: "ManageAgentUserScreen",
        icon: "iconfont icon-daili",
        layout: "/admin",
        name: "agentuser",
    },
    {
        path: "/user/manageotheruser",
        uid: UIDs.screens.admin.user.manageotheruser,
        component: "ManageOtherUserScreen",
        icon: "iconfont icon-yonghu",
        layout: "/admin",
        name: "otheruser",
    },
    {
        path: "/partner/list",
        uid: UIDs.screens.admin.partner.list,
        icon: "iconfont icon-huoban",
        component: "PartnerListScreen",
        layout: "/admin",
        name: "partner",
    },
    {
        path: "/partner/:id/edit",
        uid: UIDs.screens.admin.partner.edit,
        component: "PartnerEditScreen",
        layout: "/admin",
    },
    {
        path: "/bookassignment/:id/details",
        uid: UIDs.screens.admin.bookassignment.details,
        component: "BookAssignmentDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/bookassignment/add",
        uid: UIDs.screens.admin.bookassignment.add,
        component: "BookAssignmentAddScreen",
        layout: "/admin",
    },
    {
        path: "/bookassignment/:id/edit",
        uid: UIDs.screens.admin.bookassignment.edit,
        component: "BookAssignmentEditScreen",
        layout: "/admin",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.unittask.list,
        icon: "",
        component: "null",
        layout: "/admin",
        name: "unittask",
        grade: "1",
    },
    {
        path: "/book/list",
        uid: UIDs.screens.admin.book.list,
        icon: "iconfont icon-zhizuoxitiji",
        component: "BookListScreen",
        layout: "/admin",
        name: "book",
        grade: "",
    },
    {
        path: "/book/:id/details",
        uid: UIDs.screens.admin.book.details,
        component: "BookDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/book/:id/submit",
        uid: UIDs.screens.admin.book.submit,
        component: "BookSubmitScreen",
        layout: "/admin",
    },
    {
        path: "/book/add",
        uid: UIDs.screens.admin.book.add,
        component: "BookAddScreen",
        layout: "/admin",
    },
    {
        path: "/book/:id/edit",
        uid: UIDs.screens.admin.book.edit,
        component: "BookEditScreen",
        layout: "/admin",
    },
    {
        path: "/book/section/add",
        uid: UIDs.screens.admin.book.addsection,
        component: "BookAddSectionScreen",
        layout: "/admin",
    },
    {
        path: "/book/section/:id/edit",
        uid: UIDs.screens.admin.book.editsection,
        component: "BookEditSectionScreen",
        layout: "/admin",
    },
    {
        path: "/book/rawunit/add",
        uid: UIDs.screens.admin.book.addrawunit,
        component: "BookAddRawUnitcreen",
        layout: "/admin",
    },
    {
        path: "/book/rawunit/:id/edit",
        uid: UIDs.screens.admin.book.editrawunit,
        component: "BookEditRawUnitcreen",
        layout: "/admin",
    },
    {
        path: "/unittask/:type/list",
        uid: UIDs.screens.admin.unittask.list,
        component: "UnitTaskListScreen",
        layout: "/admin",
    },
    {
        path: "/unittask/:type/:courseid/detailslist/:status",
        uid: UIDs.screens.admin.unittask.detailslist,
        component: "UnitTaskDetailsListScreen",
        layout: "/admin",
    },
    {
        path: "/unittask/:courseId/templateconfirmation/:id",
        uid: UIDs.screens.admin.unittask.templateconfirmation,
        component: "UnitTaskTemplateConfirmationScreen",
        layout: "/admin",
    },
    {
        path: "/unittask/:courseId/templatevalidation/:id",
        uid: UIDs.screens.admin.unittask.templatevalidation,
        component: "UnitTaskTemplateValidationScreen",
        layout: "/admin",
    },
    {
        path: "/unittask/:courseId/makingpicture/:id",
        uid: UIDs.screens.admin.unittask.makingpicture,
        component: "MakingPictureScreen",
        layout: "/admin",
        name: "type1",
    },
    {
        path: "/unittask/:courseId/picturevalidation/:id",
        uid: UIDs.screens.admin.unittask.picturevalidation,
        component: "UnitTaskPictureValidationScreen",
        layout: "/admin",
    },
    {
        path: "/unittask/:courseId/testcontent/:id",
        uid: UIDs.screens.admin.unittask.testcontent,
        component: "UnitTaskTestContentScreen",
        layout: "/admin",
    },
    {
        path: "/unittask/:courseId/feedbackmodification/:id",
        uid: UIDs.screens.admin.unittask.feedbackmodification,
        component: "UnitTaskFeedbackModificationScreen",
        layout: "/admin",
    },
    {
        path: "/unittask/:courseId/prototypedesign/:id",
        uid: UIDs.screens.admin.unittask.prototypedesign,
        component: "UnitTaskPrototypeDesignScreen",
        layout: "/admin",
    },
    {
        path: "/unittask/:courseId/templatedevelopment/:id",
        uid: UIDs.screens.admin.unittask.templatedevelopment,
        component: "UnitTaskTemplateDevelopmentScreen",
        layout: "/admin",
    },
    {
        path: "/unittask/:courseId/picturevalidation/:id",
        uid: UIDs.screens.admin.unittask.picturevalidation,
        component: "UnitTaskPictureValidationScreen",
        layout: "/admin",
    },
    {
        path: "/unittask/1/list",
        uid: UIDs.screens.admin.unittask.list,
        component: "UnitTaskListScreen",
        icon: "iconfont icon-zhizuotupian",
        layout: "/admin",
        name: "type1",
    },
    {
        path: "/unittask/2/list",
        uid: UIDs.screens.admin.unittask.list,
        component: "UnitTaskListScreen",
        icon: "iconfont icon-xiaoyan",
        layout: "/admin",
        name: "type2",
    },
    {
        path: "/unittask/3/list",
        uid: UIDs.screens.admin.unittask.list,
        component: "UnitTaskListScreen",
        icon: "iconfont icon-mobanqueren",
        layout: "/admin",
        name: "type3",
    },
    {
        path: "/unittask/4/list",
        uid: UIDs.screens.admin.unittask.list,
        component: "UnitTaskListScreen",
        icon: "iconfont icon-xiaoyan",
        layout: "/admin",
        name: "type4",
    },
    {
        path: "/unittask/5/list",
        uid: UIDs.screens.admin.unittask.list,
        component: "UnitTaskListScreen",
        icon: "iconfont icon-yuanxingsheji",
        layout: "/admin",
        name: "type5",
    },
    {
        path: "/unittask/6/list",
        uid: UIDs.screens.admin.unittask.list,
        component: "UnitTaskListScreen",
        icon: "iconfont icon-kaifamoban",
        layout: "/admin",
        name: "type6",
    },
    {
        path: "/unittask/7/list",
        uid: UIDs.screens.admin.unittask.list,
        component: "UnitTaskListScreen",
        icon: "iconfont icon-ceshineirong",
        layout: "/admin",
        name: "type7",
    },
    {
        path: "/unittask/8/list",
        uid: UIDs.screens.admin.unittask.list,
        component: "UnitTaskListScreen",
        icon: "iconfont icon-fankuixiugai",
        layout: "/admin",
        name: "type8",
    },
    {
        path: "/unittask/publishinglist",
        uid: UIDs.screens.admin.unittask.publishinglist,
        component: "PublishingListScreen",
        icon: "iconfont icon-zongfahang",
        layout: "/admin",
        name: "publishing",
    },
    {
        path: "/book/:id/verification/:type",
        uid: UIDs.screens.admin.unittask.verification,
        component: "VerificationScreen",
        layout: "/admin",
        name: "verification",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.class,
        icon: "",
        component: "null",
        layout: "/admin",
        name: "teaching",
        grade: "1",
    },
    {
        path: "/class/list",
        uid: UIDs.screens.admin.class.list,
        icon: "iconfont icon-wodebanji",
        component: "ClassListScreen",
        layout: "/admin",
        name: "class",
    },
    {
        path: "/class/:id/details",
        uid: UIDs.screens.admin.class.details,
        component: "ClassDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/class/:id/shiftstudent",
        uid: UIDs.screens.admin.class.shiftstudent,
        component: "ShiftStudentScreen",
        layout: "/admin",
    },
    {
        path: "/class/:id/unregstudent",
        uid: UIDs.screens.admin.class.unregstudent,
        component: "UnregStudentScreen",
        layout: "/admin",
    },
    {
        path: "/class/:id/code",
        uid: UIDs.screens.admin.class.code,
        component: "ClassCodeScreen",
        layout: "/admin",
    },
    {
        path: "/:classId/class/:id/homeworkdetails/:submit",
        uid: UIDs.screens.admin.class.homeworkdetails,
        component: "ClassHomeworkDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/class/add",
        uid: UIDs.screens.admin.class.add,
        component: "ClassAddScreen",
        layout: "/admin",
    },
    {
        path: "/class/:id/edit",
        uid: UIDs.screens.admin.class.edit,
        component: "ClassEditScreen",
        layout: "/admin",
    },
    {
        path: "/class/student/import",
        uid: UIDs.screens.admin.class.student.import,
        component: "SIDImportScreen",
        layout: "/admin",
    },
    {
        path: "/class/student/add",
        uid: UIDs.screens.admin.class.student.add,
        component: "SIDAddScreen",
        layout: "/admin",
    },
    {
        path: "/class/student/:id/edit",
        uid: UIDs.screens.admin.class.student.edit,
        component: "SIDEditScreen",
        layout: "/admin",
    },
    {
        path: "/class/student/:id/details",
        uid: UIDs.screens.admin.class.student.details,
        component: "SIDDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/class/student/:id/:classId/changepassword",
        uid: UIDs.screens.admin.class.student.changepassword,
        component: "SIDChangePasswordScreen",
        layout: "/admin",
    },
    {
        path: "/homework/assignmentlist",
        uid: UIDs.screens.admin.homework.assignmentlist,
        icon: "iconfont icon-buzhizuoye",
        component: "AssignmentListScreen",
        layout: "/admin",
        name: "assignment",
    },
    {
        path: "/homework/assignmentpreview",
        uid: UIDs.screens.admin.homework.assignmentpreview,
        component: "AssignmentPreviewScreen",
        layout: "/admin",
    },
    {
        path: "/homework/assignmentdetails",
        uid: UIDs.screens.admin.homework.assignmentdetails,
        component: "AssignmentDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/homework/list",
        uid: UIDs.screens.admin.homework.list,
        icon: "",
        component: "HomeworkListScreen",
        layout: "/admin",
        name: "homework",
    },
    {
        path: "/homework/:id/details",
        uid: UIDs.screens.admin.homework.details,
        component: "HomeworkDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/homework/details/addunit",
        uid: UIDs.screens.admin.homework.addunit,
        component: "HomeworkAddUnitScreen",
        layout: "/admin",
    },
    {
        path: "/homework/add",
        uid: UIDs.screens.admin.homework.add,
        component: "HomeworkAddScreen",
        layout: "/admin",
    },
    {
        path: "/homework/:id/edit",
        uid: UIDs.screens.admin.homework.edit,
        component: "HomeworkEditScreen",
        layout: "/admin",
    },
    {
        path: "/homework/:id/assign",
        uid: UIDs.screens.admin.homework.assign,
        component: "HomeworkAssignScreen",
        layout: "/admin",
    },
    {
        path: "/homework/reviews/list",
        uid: UIDs.screens.admin.homework.reviewlist,
        component: "HomeworkReviewListScreen",
        layout: "/admin",
        name: "review",
    },
    {
        path: "/homework/comments/list",
        uid: UIDs.screens.admin.homework.commentlist,
        icon: "",
        component: "HomeworkCommentListScreen",
        layout: "/admin",
        name: "review",
    },
    {
        path: "/homework/comments/:homeworkAssignmentId/list/:revised",
        uid: UIDs.screens.admin.homework.classcommentlist,
        component: "HomeworkClassCommentListScreen",
        layout: "/admin",
    },
    {
        path: "/homework/classreviews/list",
        uid: UIDs.screens.admin.homework.classreviewlist,
        component: "HomeworkClassReviewListScreen",
        layout: "/admin",
    },
    {
        path: "/homework/comments/commentdetails",
        uid: UIDs.screens.admin.homework.commentdetails,
        component: "HomeworkCommentDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/homework/comments/reviewdetails",
        uid: UIDs.screens.admin.homework.reviewdetails,
        component: "HomeworkReviewDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/homework/unitdetails/:id",
        uid: UIDs.screens.admin.homework.unitdetails,
        component: "HomeworkUnitDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/homework/management/list",
        uid: UIDs.screens.admin.homework.management.list,
        icon: "iconfont icon-guanlizuoye",
        component: "HomeworkManagementListScreen",
        layout: "/admin",
        name: "management",
    },
    {
        path: "/homework/management/:id/makeuplist",
        uid: UIDs.screens.admin.homework.management.makeuplist,
        component: "HomeworkManagementMakeupListScreen",
        layout: "/admin",
    },
    {
        path: "/homework/management/homeworksummary",
        uid: UIDs.screens.admin.homework.management.homeworksummary,
        component: "HomeworkManagementHomeworkSummaryScreen",
        layout: "/admin",
    },
    {
        path: "/homework/management/:id/summary",
        uid: UIDs.screens.admin.homework.management.summary,
        component: "HomeworkManagementSummaryScreen",
        layout: "/admin",
    },
    {
        path: "/homework/management/:id/reviewlist",
        uid: UIDs.screens.admin.homework.management.reviewlist,
        component: "HomeworkManagementReviewListScreen",
        layout: "/admin",
    },
    {
        path: "/homework/management/review/:id",
        uid: UIDs.screens.admin.homework.management.review,
        component: "HomeworkManagementReviewScreen",
        layout: "/admin",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.composition.assignmentlist,
        icon: "",
        component: "null",
        layout: "/admin",
        name: "composition",
        grade: "1",
    },
    {
        path: "/composition/assignmentlist",
        uid: UIDs.screens.admin.composition.assignmentlist,
        icon: "iconfont icon-zuowen",
        component: "CompositionAssignmentListScreen",
        layout: "/admin",
        name: "assignmentComposition",
    },
    {
        path: "/composition/assignmentpreview",
        uid: UIDs.screens.admin.composition.assignmentpreview,
        component: "CompositionAssignmentPreviewScreen",
        layout: "/admin",
    },
    {
        path: "/composition/assignmentdetails",
        uid: UIDs.screens.admin.composition.assignmentdetails,
        component: "CompositionAssignmentDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/composition/management/list",
        uid: UIDs.screens.admin.composition.management.list,
        icon: "iconfont icon-guanlizuowen",
        component: "CompositionManagementListScreen",
        layout: "/admin",
        name: "managementComposition",
    },
    {
        path: "/composition/management/review/:id",
        uid: UIDs.screens.admin.composition.management.review,
        component: "CompositionManagementReviewScreen",
        layout: "/admin",
    },
    {
        path: "/composition/management/:id/summary",
        uid: UIDs.screens.admin.composition.management.review,
        component: "CompositionManagementSummaryScreen",
        layout: "/admin",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.bbs.list,
        icon: "",
        component: "null",
        layout: "/admin",
        name: "bbs",
        grade: "1",
    },
    {
        path: "/bbs/list",
        uid: UIDs.screens.admin.bbs.list,
        icon: "iconfont icon-a-lujing292",
        component: "BbsListScreen",
        layout: "/admin",
        name: "bbs",
    },
    {
        path: "/bbs/:id/details",
        uid: UIDs.screens.admin.bbs.details,
        component: "BbsDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/bbs/add",
        uid: UIDs.screens.admin.bbs.add,
        component: "BbsAddScreen",
        layout: "/admin",
    },
    {
        path: "/bbs/:id/edit",
        uid: UIDs.screens.admin.bbs.edit,
        component: "BbsEditScreen",
        layout: "/admin",
    },
    {
        path: "/bbs/me",
        uid: UIDs.screens.admin.bbs.me,
        icon: "iconfont icon-a-zu1179",
        component: "BbsMeScreen",
        layout: "/admin",
        name: "me",
    },
    {
        path: "/bbs/message",
        uid: UIDs.screens.admin.bbs.message,
        component: "BbsMessageScreen",
        layout: "/admin",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.jiaocai,
        icon: "",
        component: "null",
        layout: "/admin",
        name: "textbook",
        grade: "1",
    },
    {
        path: "/bookgroup/list",
        uid: UIDs.screens.admin.bookgroup.list,
        icon: "iconfont icon-wodexitiji",
        component: "BookgroupListScreen",
        layout: "/admin",
        name: "bookgroup",
    },
    {
        path: "/bookgroup/:id/details",
        uid: UIDs.screens.admin.bookgroup.details,
        component: "BookgroupDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/bookgroup/:id/edit",
        uid: UIDs.screens.admin.bookgroup.edit,
        component: "BookgroupEditScreen",
        layout: "/admin",
    },
    {
        path: "/product/list",
        uid: UIDs.screens.admin.product.list,
        icon: "iconfont icon-chanpin",
        component: "ProductListScreen",
        layout: "/admin",
        name: "product",
    },
    {
        path: "/product/:id/details",
        uid: UIDs.screens.admin.product.details,
        component: "ProductDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/product/add",
        uid: UIDs.screens.admin.product.add,
        component: "ProductAddScreen",
        layout: "/admin",
    },
    {
        path: "/product/:id/edit",
        uid: UIDs.screens.admin.product.edit,
        component: "ProductEditScreen",
        layout: "/admin",
    },
    {
        path: "/course/list",
        uid: UIDs.screens.admin.course.list,
        icon: "iconfont icon-kecheng",
        component: "CourseListScreen",
        layout: "/admin",
        name: "course",
    },
    {
        path: "/course/:id/details",
        uid: UIDs.screens.admin.course.details,
        component: "CourseDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/course/add",
        uid: UIDs.screens.admin.course.add,
        component: "CourseAddScreen",
        layout: "/admin",
    },
    {
        path: "/course/:id/edit",
        uid: UIDs.screens.admin.course.edit,
        component: "CourseEditScreen",
        layout: "/admin",
    },
    {
        path: "/section/list",
        uid: UIDs.screens.admin.section.list,
        icon: "iconfont icon-zhangjie",
        component: "SectionListScreen",
        layout: "/admin",
        name: "section",
    },
    {
        path: "/section/:id/details",
        uid: UIDs.screens.admin.section.details,
        component: "SectionDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/section/add",
        uid: UIDs.screens.admin.section.add,
        component: "SectionAddScreen",
        layout: "/admin",
    },
    {
        path: "/section/:id/edit",
        uid: UIDs.screens.admin.section.edit,
        component: "SectionEditScreen",
        layout: "/admin",
    },
    {
        path: "/unit/list",
        uid: UIDs.screens.admin.unit.list,
        icon: "iconfont icon-danyuan",
        component: "UnitListScreen",
        layout: "/admin",
        name: "unit",
    },
    {
        path: "/unit/:id/details",
        uid: UIDs.screens.admin.unit.details,
        component: "UnitDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/unit/add",
        uid: UIDs.screens.admin.unit.add,
        component: "UnitAddScreen",
        layout: "/admin",
    },
    {
        path: "/unit/batchadd",
        uid: UIDs.screens.admin.unit.batchadd,
        component: "UnitBatchAddScreen",
        layout: "/admin",
    },
    {
        path: "/unit/simplebatchadd",
        uid: UIDs.screens.admin.unit.simplebatchadd,
        component: "UnitSimpleBatchAddScreen",
        layout: "/admin",
    },
    {
        path: "/unit/batchexport",
        uid: UIDs.screens.admin.unit.batchexport,
        component: "UnitBatchExportScreen",
        layout: "/admin",
    },
    {
        path: "/unit/:id/edit",
        uid: UIDs.screens.admin.unit.edit,
        component: "UnitEditScreen",
        layout: "/admin",
    },
    {
        path: "/unit/move",
        uid: UIDs.screens.admin.unit.move,
        component: "UnitMoveScreen",
        layout: "/admin",
    },
    {
        path: "/oralbook/list",
        uid: UIDs.screens.admin.oralbook.list,
        icon: "iconfont icon-kouyuxitiji",
        component: "OralBookListScreen",
        layout: "/admin",
        name: "oralbook",
    },
    {
        path: "/oralbook/:id/details",
        uid: UIDs.screens.admin.oralbook.details,
        component: "OralBookDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/oralbook/:id/submit",
        uid: UIDs.screens.admin.oralbook.submit,
        component: "OralBookSubmitScreen",
        layout: "/admin",
    },
    {
        path: "/oralbook/add",
        uid: UIDs.screens.admin.oralbook.add,
        component: "OralBookAddScreen",
        layout: "/admin",
    },
    {
        path: "/oralbook/:id/edit",
        uid: UIDs.screens.admin.oralbook.edit,
        component: "OralBookEditScreen",
        layout: "/admin",
    },
    {
        path: "/oralbook/section/add",
        uid: UIDs.screens.admin.oralbook.addsection,
        component: "OralBookAddSectionScreen",
        layout: "/admin",
    },
    {
        path: "/oralbook/section/:id/edit",
        uid: UIDs.screens.admin.oralbook.editsection,
        component: "OralBookEditSectionScreen",
        layout: "/admin",
    },
    {
        path: "/oralbook/unit/add",
        uid: UIDs.screens.admin.oralbook.addunit,
        component: "OralBookAddUnitcreen",
        layout: "/admin",
    },
    {
        path: "/oralbook/unit/:id/edit",
        uid: UIDs.screens.admin.oralbook.editunit,
        component: "OralBookEditUnitcreen",
        layout: "/admin",
    },
    {
        path: "/readingbook/list",
        uid: UIDs.screens.admin.readingbook.list,
        icon: "iconfont icon-yueduxitiji",
        component: "ReadingBookListScreen",
        layout: "/admin",
        name: "readingbook",
    },
    {
        path: "/readingbook/:id/details",
        uid: UIDs.screens.admin.readingbook.details,
        component: "ReadingBookDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/readingbook/:id/submit",
        uid: UIDs.screens.admin.readingbook.submit,
        component: "ReadingBookSubmitScreen",
        layout: "/admin",
    },
    {
        path: "/readingbook/add",
        uid: UIDs.screens.admin.readingbook.add,
        component: "ReadingBookAddScreen",
        layout: "/admin",
    },
    {
        path: "/readingbook/:id/edit",
        uid: UIDs.screens.admin.readingbook.edit,
        component: "ReadingBookEditScreen",
        layout: "/admin",
    },
    {
        path: "/readingbook/section/add",
        uid: UIDs.screens.admin.readingbook.addsection,
        component: "ReadingBookAddSectionScreen",
        layout: "/admin",
    },
    {
        path: "/readingbook/section/:id/edit",
        uid: UIDs.screens.admin.readingbook.editsection,
        component: "ReadingBookEditSectionScreen",
        layout: "/admin",
    },
    {
        path: "/readingbook/unit/add",
        uid: UIDs.screens.admin.readingbook.addunit,
        component: "ReadingBookAddUnitcreen",
        layout: "/admin",
    },
    {
        path: "/readingbook/unit/:id/edit",
        uid: UIDs.screens.admin.readingbook.editunit,
        component: "ReadingBookEditUnitcreen",
        layout: "/admin",
    },
    {
        path: "/talkchinese/list",
        uid: UIDs.screens.admin.talkchinese.list,
        icon: "iconfont icon-kaikouhanyu1",
        component: "TalkChineseListScreen",
        layout: "/admin",
        name: "talkchinese",
        grade: "0",
    },
    {
        path: "/talkchinese/add",
        uid: UIDs.screens.admin.talkchinese.add,
        component: "TalkChineseAddScreen",
        layout: "/admin",
    },
    {
        path: "/talkchinese/:id/edit",
        uid: UIDs.screens.admin.talkchinese.edit,
        component: "TalkChineseEditScreen",
        layout: "/admin",
    },
    {
        path: "/talkchinese/adddialog",
        uid: UIDs.screens.admin.talkchinese.adddialog,
        component: "TalkChineseAddDialogScreen",
        layout: "/admin",
    },
    {
        path: "/talkchinese/:id/editdialog",
        uid: UIDs.screens.admin.talkchinese.editdialog,
        component: "TalkChineseEditDialogScreen",
        layout: "/admin",
    },
    {
        path: "/talkchinese/:id/details",
        uid: UIDs.screens.admin.talkchinese.details,
        component: "TalkChineseDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/bookassignment/list",
        uid: UIDs.screens.admin.bookassignment.list,
        icon: "iconfont icon-fenpeixitiji",
        component: "BookAssignmentListScreen",
        layout: "/admin",
        name: "assignbook",
    },
    {
        path: "/teaching/course/list",
        uid: UIDs.screens.admin.teaching.course.list,
        icon: "",
        component: "CourseListScreen",
        layout: "/admin",
        name: "courses",
    },
    {
        path: "/:classId/class/:id/unsubmitdetails",
        uid: UIDs.screens.admin.homework.unsubmitdetails,
        component: "UnsubmitDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/exam/list",
        uid: UIDs.screens.admin.exam.list,
        icon: "",
        component: "ExamListScreen",
        layout: "/admin",
        name: "exam",
        grade: "1",
    },
    {
        path: "/exam/:id/details",
        uid: UIDs.screens.admin.exam.details,
        component: "ExamDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/exam/add",
        uid: UIDs.screens.admin.exam.add,
        component: "ExamAddScreen",
        layout: "/admin",
    },
    {
        path: "/exam/:id/edit",
        uid: UIDs.screens.admin.exam.edit,
        component: "ExamEditScreen",
        layout: "/admin",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.dictionary.letter.list,
        icon: "",
        component: "null",
        layout: "/admin",
        name: "dictionary",
        grade: "1",
    },
    {
        path: "/dictionary/letter/list",
        uid: UIDs.screens.admin.dictionary.letter.list,
        icon: "iconfont icon-zi",
        component: "LetterListScreen",
        layout: "/admin",
        name: "letter",
        grade: "",
    },
    {
        path: "/dictionary/letter/:id/details",
        uid: UIDs.screens.admin.dictionary.letter.details,
        icon: "",
        component: "LetterDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/letter/add",
        uid: UIDs.screens.admin.dictionary.letter.add,
        icon: "",
        component: "LetterAddScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/letter/:id/edit",
        uid: UIDs.screens.admin.dictionary.letter.edit,
        icon: "",
        component: "LetterEditScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/lettersen/list",
        uid: UIDs.screens.admin.dictionary.lettersen.list,
        icon: "iconfont icon-zi",
        component: "LettersEnListScreen",
        layout: "/admin",
        name: "lettersen",
        grade: "",
    },
    {
        path: "/dictionary/lettersen/:id/details",
        uid: UIDs.screens.admin.dictionary.lettersen.details,
        icon: "",
        component: "LettersEnDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/lettersen/add",
        uid: UIDs.screens.admin.dictionary.lettersen.add,
        icon: "",
        component: "LettersEnAddScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/lettersen/:id/edit",
        uid: UIDs.screens.admin.dictionary.lettersen.edit,
        icon: "",
        component: "LettersEnEditScreen",
        layout: "/admin",
    },


    {
        path: "/dictionary/pinyin/list",
        uid: UIDs.screens.admin.dictionary.pinyin.list,
        icon: "iconfont icon-pin",
        component: "PinyinListScreen",
        layout: "/admin",
        name: "pinyin",
        grade: "",
    },
    {
        path: "/dictionary/pinyin/:spelling/details",
        uid: UIDs.screens.admin.dictionary.pinyin.details,
        icon: "",
        component: "PinyinDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/pinyin/add",
        uid: UIDs.screens.admin.dictionary.pinyin.add,
        icon: "",
        component: "PinyinAddScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/pinyin/edit",
        uid: UIDs.screens.admin.dictionary.pinyin.edit,
        icon: "",
        component: "PinyinEditScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/radical/list",
        uid: UIDs.screens.admin.dictionary.radical.list,
        icon: "iconfont icon-pianpangbushou",
        component: "RadicalListScreen",
        layout: "/admin",
        name: "radical",
        grade: "",
    },
    {
        path: "/dictionary/radical/:id/details",
        uid: UIDs.screens.admin.dictionary.radical.details,
        icon: "",
        component: "RadicalDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/radical/add",
        uid: UIDs.screens.admin.dictionary.radical.add,
        icon: "",
        component: "RadicalAddScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/radical/:id/edit",
        uid: UIDs.screens.admin.dictionary.radical.edit,
        icon: "",
        component: "RadicalEditScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/word/list",
        uid: UIDs.screens.admin.dictionary.word.list,
        icon: "iconfont icon-ciyu",
        component: "WordListScreen",
        layout: "/admin",
        name: "word",
        grade: "",
    },
    {
        path: "/dictionary/word/:id/details",
        uid: UIDs.screens.admin.dictionary.word.details,
        icon: "",
        component: "WordDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/word/add",
        uid: UIDs.screens.admin.dictionary.word.add,
        icon: "",
        component: "WordAddScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/word/batchadd",
        uid: UIDs.screens.admin.dictionary.word.batchadd,
        icon: "",
        component: "WordBatchAddScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/word/:id/edit",
        uid: UIDs.screens.admin.dictionary.word.edit,
        icon: "",
        component: "WordEditScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/word/:id/editjson",
        uid: UIDs.screens.admin.dictionary.word.editjson,
        icon: "",
        component: "WordEditJsonScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/sentence/list",
        uid: UIDs.screens.admin.dictionary.sentence.list,
        icon: "iconfont icon-dujuzi",
        component: "SentenceListScreen",
        layout: "/admin",
        name: "sentence",
        grade: "",
    },
    {
        path: "/dictionary/sentence/:id/details",
        uid: UIDs.screens.admin.dictionary.sentence.details,
        icon: "",
        component: "SentenceDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/dictionary/sentence/:id/edit",
        uid: UIDs.screens.admin.dictionary.sentence.edit,
        icon: "",
        component: "SentenceEditScreen",
        layout: "/admin",
    },
    {
        path: "/syllable/list",
        uid: UIDs.screens.admin.syllable.list,
        icon: "iconfont icon-pinyin",
        component: "SyllableListScreen",
        layout: "/admin",
        name: "syllable",
        grade: "",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.setting,
        icon: "",
        component: "SettingScreen",
        layout: "/admin",
        name: "configuration",
        grade: "1",
    },
    {
        path: "/setting",
        uid: UIDs.screens.admin.setting,
        icon: "iconfont icon-shezhi",
        component: "SettingScreen",
        layout: "/admin",
        name: "settings",
        grade: "0",
    },
    {
        path: "/voicesetting",
        uid: UIDs.screens.admin.voicesetting,
        icon: "iconfont icon-yuyinshezhi",
        component: "VoiceSettingScreen",
        layout: "/admin",
        name: "voiceSettings",
        grade: "0",
    },
    {
        path: "/automarknumber",
        uid: UIDs.screens.admin.automarknumber,
        icon: "iconfont icon-yuyinshezhi",
        component: "AutoMarkNumberScreen",
        layout: "/admin",
        name: "AutoMarkNumber",
        grade: "0",
    },
    {
        path: "/servicesetting",
        uid: UIDs.screens.admin.servicesetting,
        icon: "iconfont icon-fuwushezhi",
        component: "ServiceSettingScreen",
        layout: "/admin",
        name: "serviceSettings",
        grade: "0",
    },
    {
        path: "/regionserversetting",
        uid: UIDs.screens.admin.regionserversetting,
        icon: "iconfont icon-fuwuqishezhi",
        component: "RegionServerSettingScreen",
        layout: "/admin",
        name: "regionServerSettings",
        grade: "0",
    },
    {
        path: "/testspeechrecognition",
        uid: UIDs.screens.admin.testspeechrecognition,
        icon: "iconfont icon-ceshiyuyin",
        component: "TestSpeechRecognitionScreen",
        layout: "/admin",
        name: "testSpeechRecognition",
        grade: "0",
    },
    {
        path: "/offlineproblem",
        uid: UIDs.screens.admin.servicesetting,
        icon: "iconfont icon-lixian1",
        component: "OfflineProblemScreen",
        layout: "/admin",
        name: "offlineproblems",
        grade: "0",
    },
    {
        path: "/redenvelopesetting",
        uid: UIDs.screens.admin.redenvelopesetting,
        icon: "iconfont icon-hongbao",
        component: "RedEnvelopeSettingScreen",
        layout: "/admin",
        name: "redenvelopesettings",
    },
    {
        path: "/chineseparksetting",
        uid: UIDs.screens.admin.chineseparksetting,
        icon: "iconfont icon-hanyujiaofuwuduan",
        component: "ChineseParkSettingScreen",
        layout: "/admin",
        name: "chineseparksettings",
    },
    {
        path: "/featureconfiguration",
        uid: UIDs.screens.admin.featureconfiguration,
        icon: "iconfont icon-kaiguan",
        component: "FeatureConfigurationScreen",
        layout: "/admin",
        name: "featureconfiguration",
    },
    {
        path: "/currencyinfo",
        uid: UIDs.screens.admin.currencyInfo,
        icon: "iconfont icon-huobileixing",
        component: "CurrencyInfoScreen",
        layout: "/admin",
        name: "currencyinfo",
    },
    {
        path: "/unittype/list",
        uid: UIDs.screens.admin.unittype.list,
        icon: "iconfont icon-danyuan1",
        component: "UnitTypeListScreen",
        layout: "/admin",
        name: "unitTypes",
        grade: "0",
    },
    {
        path: "/unittype/:id/details",
        uid: UIDs.screens.admin.unittype.details,
        component: "UnitTypeDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/unittype/add",
        uid: UIDs.screens.admin.unittype.add,
        component: "UnitTypeAddScreen",
        layout: "/admin",
    },
    {
        path: "/unittype/:id/edit",
        uid: UIDs.screens.admin.unittype.edit,
        component: "UnitTypeEditScreen",
        layout: "/admin",
    },
    {
        path: "/unittitle/list",
        uid: UIDs.screens.admin.unittitle.list,
        icon: "iconfont icon-danyuanbiaoti",
        component: "UnitTitleListScreen",
        layout: "/admin",
        name: "unitTitles",
        grade: "0",
    },
    {
        path: "/unittitle/:id/details",
        uid: UIDs.screens.admin.unittitle.details,
        component: "UnitTitleDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/unittitle/add",
        uid: UIDs.screens.admin.unittitle.add,
        component: "UnitTitleAddScreen",
        layout: "/admin",
    },
    {
        path: "/unittitle/:id/edit",
        uid: UIDs.screens.admin.unittitle.edit,
        component: "UnitTitleEditScreen",
        layout: "/admin",
    },
    {
        path: "/language/list",
        uid: UIDs.screens.admin.language.list,
        icon: "",
        component: "LanguageListScreen",
        layout: "/admin",
        name: "languages",
        grade: "0",
    },
    {
        path: "/language/:code/details",
        uid: UIDs.screens.admin.language.details,
        component: "LanguageDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/language/add",
        uid: UIDs.screens.admin.language.add,
        component: "LanguageAddScreen",
        layout: "/admin",
    },
    {
        path: "/language/:code/edit",
        uid: UIDs.screens.admin.language.edit,
        component: "LanguageEditScreen",
        layout: "/admin",
    },
    {
        path: "/apiexceptionrule/list",
        uid: UIDs.screens.admin.apiexceptionrule.list,
        icon: "iconfont icon-yichangguanli",
        component: "ExceptionEndpointListScreen",
        layout: "/admin",
        name: "apiexceptionrule",
        grade: "0",
    },
    {
        path: "/apiexceptionrule/:id/details",
        uid: UIDs.screens.admin.apiexceptionrule.details,
        icon: "",
        component: "ExceptionEndpointDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/apiexceptionrule/add",
        uid: UIDs.screens.admin.apiexceptionrule.add,
        icon: "",
        component: "ExceptionEndpointAddScreen",
        layout: "/admin",
    },
    {
        path: "/unittaskconfiguration/list",
        uid: UIDs.screens.admin.unittaskconfiguration.list,
        icon: "iconfont icon-jiaocairenwu",
        component: "UnitTaskConfigurationListScreen",
        layout: "/admin",
        name: "unittaskconfiguration",
        grade: "0",
    },
    {
        path: "/unittaskconfiguration/add",
        uid: UIDs.screens.admin.unittaskconfiguration.add,
        component: "UnitTaskConfigurationAddScreen",
        layout: "/admin",
    },
    {
        path: "/unittaskconfiguration/:id/edit",
        uid: UIDs.screens.admin.unittaskconfiguration.edit,
        component: "UnitTaskConfigurationEditScreen",
        layout: "/admin",
    },
    {
        path: "/chineseparkserver/list",
        uid: UIDs.screens.admin.chineseparkserver.list,
        icon: "iconfont icon-hanyujiaofuwuduan",
        component: "ChineseParkServerListScreen",
        layout: "/admin",
        name: "chineseparkserver",
        grade: "0",
    },
    {
        path: "/chineseparkserver/add",
        uid: UIDs.screens.admin.chineseparkserver.add,
        component: "ChineseParkServerAddScreen",
        layout: "/admin",
    },
    {
        path: "/chineseparkserver/:id/edit",
        uid: UIDs.screens.admin.chineseparkserver.edit,
        component: "ChineseParkServerEditScreen",
        layout: "/admin",
    },
    {
        path: "/chineseparkserver/:id/details",
        uid: UIDs.screens.admin.chineseparkserver.details,
        component: "ChineseParkServerDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/emailverificationcode/list",
        uid: UIDs.screens.admin.emailverificationcode.list,
        icon: "iconfont icon-yanzhengma",
        component: "EmailVerificationCodeListScreen",
        layout: "/admin",
        name: "emailverificationcode",
        grade: "0",
    },
    {
        path: "/phoneverificationcode/list",
        uid: UIDs.screens.admin.phoneverificationcode.list,
        icon: "iconfont icon-yanzhengma",
        component: "PhoneVerificationCodeListScreen",
        layout: "/admin",
        name: "phoneverificationcode",
        grade: "0",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.suggestion.list,
        icon: "",
        component: "null",
        layout: "/admin",
        name: "suggestion",
        grade: "1",
    },
    {
        path: "/suggestion/list",
        uid: UIDs.screens.admin.suggestion.list,
        icon: "iconfont icon-appfankui",
        component: "SuggestionListScreen",
        layout: "/admin",
        name: "appFeedback",
    },
    {
        path: "/suggestion/:id/details",
        uid: UIDs.screens.admin.suggestion.details,
        icon: "",
        component: "SuggestionDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/contact/list",
        uid: UIDs.screens.admin.contact.list,
        icon: "iconfont icon-xinxifankui",
        component: "ContactListScreen",
        layout: "/admin",
        name: "contactList",
    },
    {
        path: "/contact/:id/details",
        uid: UIDs.screens.admin.contact.details,
        icon: "",
        component: "ContactDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/messageboard/list",
        uid: UIDs.screens.admin.messageboard.list,
        icon: "iconfont icon-liuyanban",
        component: "MessageBoardListScreen",
        layout: "/admin",
        name: "messageBoard",
    },
    {
        path: "/messageboard/detailslist",
        uid: UIDs.screens.admin.messageboard.detailslist,
        icon: "",
        component: "MessageBoardDetailsListScreen",
        layout: "/admin",
    },
    {
        path: "/messageboard/:id/details",
        uid: UIDs.screens.admin.messageboard.details,
        icon: "",
        component: "MessageBoardDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/error/list",
        uid: UIDs.screens.admin.error.list,
        icon: "",
        component: "ErrorListScreen",
        layout: "/admin",
        name: "error",
    },
    {
        path: "/error/dashboard",
        uid: UIDs.screens.admin.error.dashboard,
        icon: "iconfont icon-cuowuxinxi",
        component: "ErrorDashboardScreen",
        layout: "/admin",
        name: "errorDashBoard",
    },
    {
        path: "/error/:id/details",
        uid: UIDs.screens.admin.error.details,
        icon: "",
        component: "ErrorDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/contact/edit",
        uid: UIDs.screens.admin.contact.edit,
        icon: "",
        component: "ContactEditScreen",
        layout: "/admin",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.book.deleted,
        icon: "",
        component: "null",
        layout: "/admin",
        name: "recycleBin",
        grade: "1",
    },
    {
        path: "/book/deleted",
        uid: UIDs.screens.admin.book.deleted,
        component: "BookDeletedScreen",
        icon: "iconfont icon-shanchudexitiji",
        layout: "/admin",
        name: "deletedBooks",
    },
    {
        path: "/oralbook/unitlist",
        uid: UIDs.screens.admin.oralbook.unitlist,
        component: "OralBookUnitListScreen",
        icon: "iconfont icon-ceshizhuanyong",
        layout: "/admin",
        name: "onlyTest",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.talkchinese.statistic,
        icon: "",
        component: "null",
        layout: "/admin",
        name: "talkchinese",
        grade: "1",
    },
    {
        path: "/talkchinese/statistic",
        uid: UIDs.screens.admin.talkchinese.statistic,
        icon: "iconfont icon-shujutongji",
        component: "TalkChineseStatisticScreen",
        layout: "/admin",
        name: "dataStatistic",
        grade: "",
    },
    {
        path: "/talkchinese/homeworkstat",
        uid: UIDs.screens.admin.homework.statistic,
        icon: "iconfont icon-shujutongji",
        component: "HomeworkStatisticScreen",
        layout: "/admin",
        name: "homeworkStatistic",
        grade: "",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.agent,
        icon: "",
        component: "null",
        layout: "/admin",
        name: "agentSystem",
        grade: "1",
    },
    {
        path: "/agent/agentreferralcode",
        uid: UIDs.screens.admin.agent.agentreferralcode,
        component: "AgentReferralCodeScreen",
        layout: "/admin",
        name: "referralcode",
    },
    {
        path: "/agent/useraccount/list",
        uid: UIDs.screens.admin.agent.useraccount.list,
        icon: "iconfont icon-kaishexinlaoshi",
        component: "UserAccountListScreen",
        layout: "/admin",
        name: "userAccount",
        grade: "",
    },
    {
        path: "/agent/useraccount/add",
        uid: UIDs.screens.admin.agent.useraccount.add,
        icon: "",
        component: "UserAccountAddScreen",
        layout: "/admin",
    },
    {
        path: "/agent/useraccount/edit",
        uid: UIDs.screens.admin.agent.useraccount.edit,
        icon: "",
        component: "UserAccountEditScreen",
        layout: "/admin",
    },
    {
        path: "/agent/useraccount/code",
        uid: UIDs.screens.admin.agent.useraccount.code,
        icon: "",
        component: "UserAccountCodeScreen",
        layout: "/admin",
    },
    {
        path: "/agent/useraccount/:id/changepassword",
        uid: UIDs.screens.admin.agent.useraccount.changepassword,
        component: "UserAccountChangePasswordScreen",
        layout: "/admin",
    },
    {
        path: "/agent/statistic",
        uid: UIDs.screens.admin.agent.statistic,
        icon: "iconfont icon-shujutongji",
        component: "AgentStatisticScreen",
        layout: "/admin",
        name: "dataStatistic",
    },
    {
        path: "/agent/wallet",
        uid: UIDs.screens.admin.agent.wallet,
        icon: "iconfont icon-qianbao",
        component: "WalletScreen",
        layout: "/admin",
        name: "mywallet",
    },
    {
        path: "/agent/withdrawal",
        uid: UIDs.screens.admin.agent.withdrawal,
        icon: "",
        component: "WithdrawalScreen",
        layout: "/admin",
    },
    {
        path: "/agent/bankcard/add",
        uid: UIDs.screens.admin.agent.bankcard.add,
        icon: "",
        component: "BankCardAddScreen",
        layout: "/admin",
    },
    {
        path: "/agent/bankcard/:id/details",
        uid: UIDs.screens.admin.agent.bankcard.details,
        icon: "",
        component: "BankCardDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/agent/bankcard/edit",
        uid: UIDs.screens.admin.agent.bankcard.edit,
        icon: "",
        component: "BankCardEditScreen",
        layout: "/admin",
    },
    {
        path: "/agent/withdrawalcomplete",
        uid: UIDs.screens.admin.agent.withdrawalcomplete,
        icon: "",
        component: "WithdrawalCompleteScreen",
        layout: "/admin",
    },
    {
        path: "/agent/:name/:userid/studentcapitalflow",
        uid: UIDs.screens.admin.agent.studentcapitalflow,
        component: "StudentCapitalflowScreen",
        layout: "/admin",
    },
    {
        path: "/agent/subagent/list",
        uid: UIDs.screens.admin.agent.subagent.list,
        icon: "",
        component: "SubagentListScreen",
        layout: "/admin",
        name: "subAgent",
        grade: "",
    },
    {
        path: "/agent/subagent/add",
        uid: UIDs.screens.admin.agent.subagent.add,
        icon: "",
        component: "SubagentAddScreen",
        layout: "/admin",
    },
    {
        path: "/agent/subagent/edit",
        uid: UIDs.screens.admin.agent.subagent.edit,
        icon: "",
        component: "SubagentEditScreen",
        layout: "/admin",
    },
    {
        path: "/agent/subagent/code",
        uid: UIDs.screens.admin.agent.subagent.code,
        icon: "",
        component: "SubagentCodeScreen",
        layout: "/admin",
    },
    {
        path: "/agent/subagent/:id/details",
        uid: UIDs.screens.admin.agent.subagent.details,
        icon: "",
        component: "SubagentDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/agent/teacheraccount/add",
        uid: UIDs.screens.admin.agent.teacheraccount.add,
        icon: "",
        component: "TeacherAccountAddScreen",
        layout: "/admin",
    },
    {
        path: "/agent/teacheraccount/edit",
        uid: UIDs.screens.admin.agent.teacheraccount.edit,
        icon: "",
        component: "TeacherAccountEditScreen",
        layout: "/admin",
    },
    {
        path: "/agent/teacheraccount/:id/changepassword",
        uid: UIDs.screens.admin.agent.teacheraccount.changepassword,
        component: "TeacherAccountChangePasswordScreen",
        layout: "/admin",
    },
    {
        path: "/agent/teacheraccount/code",
        uid: UIDs.screens.admin.agent.teacheraccount.code,
        icon: "",
        component: "TeacherAccountCodeScreen",
        layout: "/admin",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.agent.management.list,
        icon: "",
        component: "null",
        layout: "/admin",
        name: "agentManagementSystem",
        grade: "1",
    },
    {
        path: "/agent/management/list",
        uid: UIDs.screens.admin.agent.management.list,
        icon: "iconfont icon-dailiguanli",
        component: "AgentManagementListScreen",
        layout: "/admin",
        name: "agentManagement",
    },
    {
        path: "/agent/management/:id/details",
        uid: UIDs.screens.admin.agent.management.details,
        icon: "",
        component: "AgentManagementDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/agent/management/add",
        uid: UIDs.screens.admin.agent.management.add,
        icon: "",
        component: "AgentManagementAddScreen",
        layout: "/admin",
    },
    {
        path: "/agent/management/edit",
        uid: UIDs.screens.admin.agent.management.edit,
        icon: "",
        component: "AgentManagementEditScreen",
        layout: "/admin",
    },
    {
        path: "/agent/management/code",
        uid: UIDs.screens.admin.agent.management.code,
        icon: "",
        component: "AgentManagementCodeScreen",
        layout: "/admin",
    },
    /*{
        path: "/agent/management/:status/agentapplicationlist",
        uid: UIDs.screens.admin.agent.management.agentapplicationlist,
        component: "AgentApplicationListScreen",
        layout: "/admin",
    },
    {
        path: "/agent/management/-1/agentapplicationlist",
        uid: UIDs.screens.admin.agent.management.agentapplicationlist,
        component: "AgentApplicationListScreen",
        layout: "/admin",
        name: "agentapplicationlist",
    },*/
    {
        path: "/agent/management/:id/agentapplicationdetails",
        uid: UIDs.screens.admin.agent.management.agentapplicationdetails,
        component: "AgentApplicationDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/agent/management/statistic",
        uid: UIDs.screens.admin.agent.management.statistic,
        component: "ManagementStatisticScreen",
        icon: "iconfont icon-shujutongji",
        layout: "/admin",
        name: "dataStatistic",
    },
    {
        path: "/agent/management/:status/withdrawallist",
        uid: UIDs.screens.admin.agent.management.withdrawallist,
        component: "WithdrawalListScreen",
        layout: "/admin",
    },
    {
        path: "/agent/management/-1/withdrawallist",
        uid: UIDs.screens.admin.agent.management.withdrawallist,
        component: "WithdrawalListScreen",
        icon: "iconfont icon-dingdanchuli",
        layout: "/admin",
        name: "withdrawalList",
    },
    {
        path: "/agent/management/:id/withdrawaldetails",
        uid: UIDs.screens.admin.agent.management.withdrawaldetails,
        component: "WithdrawalDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/agent/management/incomestatistic",
        uid: UIDs.screens.admin.agent.management.incomestatistic,
        component: "IncomeStatisticScreen",
        icon: "iconfont icon-shourutongji",
        layout: "/admin",
        name: "incomeStatistic",
    },
    //活动--公司后台
    {
        path: "#",
        uid: UIDs.screens.admin.other,
        icon: "",
        component: "PromotionManagementListScreen",
        layout: "/admin",
        name: "other",
        grade: "1",
    },
    {
        path: "/promotion/management/list",
        uid: UIDs.screens.admin.promotion.management.list,
        icon: "iconfont icon-cuxiao",
        component: "PromotionManagementListScreen",
        layout: "/admin",
        name: "promotion",
    },
    {
        path: "/promotion/management/add",
        uid: UIDs.screens.admin.promotion.management.add,
        component: "PromotionManagementAddScreen",
        layout: "/admin",
    },
    {
        path: "/promotion/management/:id/edit",
        uid: UIDs.screens.admin.promotion.management.edit,
        component: "PromotionManagementEditScreen",
        layout: "/admin",
    },
    {
        path: "/promotion/management/:id/details",
        uid: UIDs.screens.admin.promotion.management.details,
        component: "PromotionManagementDetailsScreen",
        layout: "/admin",
    },
    {
        path: "/promotion/management/:id/shareagentlist",
        uid: UIDs.screens.admin.promotion.management.shareagentlist,
        component: "PromotionManagementShareAgentListScreen",
        layout: "/admin",
    },
    {
        path: "/promotion/management/:id/shareagentadd",
        uid: UIDs.screens.admin.promotion.management.shareagentadd,
        component: "PromotionManagementShareAgentAddScreen",
        layout: "/admin",
    },
    {
        path: "/promotion/management/:type/:id/statistic",
        uid: UIDs.screens.admin.promotion.management.statistic,
        component: "PromotionManagementStatisticScreen",
        layout: "/admin",
    },
    //-----老师、代理
    {
        path: "#",
        uid: MobileUIDs.screens.chinesepark.default,
        component: "ChineseparkDefaultScreen",
        icon: "",
        layout: "/admin",
        name: "chinesepark",
        grade: "1",
    },
    {
        path: "/chinesepark/default",
        uid: MobileUIDs.screens.chinesepark.default,
        component: "ChineseparkDefaultScreen",
        icon: "iconfont icon-fangjiandating",
        layout: "/admin",
        name: "chineseparkRooms",
    },
    {
        path: "/chinesepark/roomlist",
        uid: MobileUIDs.screens.chinesepark.roomList,
        component: "ChineseparkRoomListScreen",
        icon: "iconfont icon-wodefangjian",
        layout: "/admin",
        name: "chineseparkroomlist",
    },
    {
        path: "/chinesepark/roomsetting",
        uid: MobileUIDs.screens.chinesepark.roomSetting,
        component: "ChineseparkRoomSettingScreen",
        layout: "/admin",
    },
    {
        path: "/chinesepark/roommanager",
        uid: MobileUIDs.screens.chinesepark.roomManager,
        component: "ChineseparkRoomManagerScreen",
        layout: "/admin",
    },
    {
        path: "/chinesepark/blacklist",
        uid: MobileUIDs.screens.chinesepark.blackList,
        component: "ChineseparkRoomBlackListScreen",
        layout: "/admin",
    },
    {
        path: "/chinesepark/vipsetting",
        uid: MobileUIDs.screens.chinesepark.vipFeatures,
        component: "ChineseparkVipFeaturesScreen",
        layout: "/admin",
    },
    {
        path: "/chinesepark/createroom",
        uid: MobileUIDs.screens.chinesepark.createRoom,
        component: "ChineseparkCreateRoomScreen",
        layout: "/admin",
    },
    {
        path: "/chinesepark/pkrules",
        uid: MobileUIDs.screens.chinesepark.pkRules,
        component: "ChineseparkPkRulesScreen",
        layout: "/admin",
    },
    {
        path: "/chinesepark/roommember",
        uid: MobileUIDs.screens.chinesepark.roommember,
        component: "ChineseparkRoomMemberScreen",
        layout: "/admin",
    },
    {
        path: "/chinesepark/unregstudent",
        uid: MobileUIDs.screens.chinesepark.unregstudent,
        component: "ChineseparkUnregStudentScreen",
        layout: "/admin",
    },
    {
        path: "/chinesepark/shiftstudent",
        uid: MobileUIDs.screens.chinesepark.shiftstudent,
        component: "ChineseparkShiftStudentScreen",
        layout: "/admin",
    },
    {
        path: "/chinesepark/addroommember",
        uid: MobileUIDs.screens.chinesepark.addroommember,
        component: "ChineseparkAddRoomMemberScreen",
        layout: "/admin",
    },
    {
        path: "/chinesepark/editroommember",
        uid: MobileUIDs.screens.chinesepark.editroommember,
        component: "ChineseparkEditRoomMemberScreen",
        layout: "/admin",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.manage,
        icon: "",
        component: "TeacherAccountListScreen",
        layout: "/admin",
        name: "manage",
        grade: "1",
    },
    {
        path: "/partner/user/list",
        uid: UIDs.screens.admin.partner.user.list,
        icon: "iconfont icon-xiezuo",
        component: "PartnerUserListScreen",
        layout: "/admin",
        name: "partnerUser",
    },
    {
        path: "/partner/user/add",
        uid: UIDs.screens.admin.partner.user.add,
        component: "PartnerUserAddScreen",
        layout: "/admin",
    },
    {
        path: "/partner/user/:id/edit",
        uid: UIDs.screens.admin.partner.user.edit,
        component: "PartnerUserEditScreen",
        layout: "/admin",
    },
    {
        path: "/partner/user/:id/changepassword",
        uid: UIDs.screens.admin.partner.user.changepassword,
        component: "PartnerUserChangePasswordScreen",
        layout: "/admin",
    },
    {
        path: "/agent/teacheraccount/list",
        uid: UIDs.screens.admin.agent.teacheraccount.list,
        icon: "iconfont icon-kaishexinlaoshi",
        component: "TeacherAccountListScreen",
        layout: "/admin",
        name: "createTeacher",
        grade: "",
    },
    {
        path: "/agent/redenvelope",
        uid: UIDs.screens.admin.agent.redenvelope,
        icon: "",
        component: "RedEnvelopeScreen",
        layout: "/admin",
        name: "redenvelope",
    },
    {
        path: "#",
        uid: UIDs.screens.admin.promotion.promotionlist,
        icon: "",
        component: "PromotionListScreen",
        layout: "/admin",
        name: "marketing",
        grade: "1",
    },
    {
        path: "/promotion/promotionlist",
        uid: UIDs.screens.admin.promotion.promotionlist,
        icon: "iconfont icon-cuxiao",
        component: "PromotionListScreen",
        layout: "/admin",
        name: "promotion",
    },
    {
        path: "/promotion/:type/:id/statistic",
        uid: UIDs.screens.admin.promotion.statistic,
        component: "PromotionStatisticScreen",
        layout: "/admin",
    },
    {
        path: "/promotion/:id/sharestatistic",
        uid: UIDs.screens.admin.promotion.sharestatistic,
        component: "PromotionShareStatisticScreen",
        layout: "/admin",
    },
    {
        path: "/promotion/:id/redenvelopestatistic",
        uid: UIDs.screens.admin.promotion.redenvelopestatistic,
        component: "PromotionRedEnvelopeStatisticScreen",
        layout: "/admin",
    },

    /*其它菜单请加在上面*/
    // {
    //     path: "#",
    //     uid: UIDs.screens.admin.icons,
    //     icon: "",
    //     component: "null",
    //     layout: "/admin",
    //     name: "Resources",
    //     grade:"1"
    // },
    // {
    //     path: "/example",
    //     uid: UIDs.screens.admin.example,
    //     icon: "",
    //     component: "ExampleScreen",
    //     layout: "/admin",
    //     name: "Example",
    //     grade:""
    // },
    // {
    //     path: "/icons",
    //     uid: UIDs.screens.admin.icons,
    //     icon: "",
    //     component: "IconsScreen",
    //     layout: "/admin",
    //     name: "Icons",
    //     grade:""
    // },
    // {
    //     path: "/maps",
    //     uid: UIDs.screens.admin.maps,
    //     icon: "",
    //     component: "MapsScreen",
    //     layout: "/admin",
    //     name: "Maps",
    //     grade:""
    // },
    {
        path: "/user-profile",
        uid: UIDs.screens.admin.profile,
        component: "ProfileScreen",
        layout: "/admin",
    },
    // {
    //     path: "/tables",
    //     uid: UIDs.screens.admin.tables,
    //     icon: "",
    //     component: "TablesScreen",
    //     layout: "/admin",
    //     name: "Tables",
    //     grade:""
    // },

    /* Portal Layout */
    {
        path: "/portal",
        uid: UIDs.layouts.portal,
        icon: "",
        component: "null",
        layout: "",
        name: "Portal",
        grade: "",
    },
    {
        path: "/home",
        uid: UIDs.screens.portal.home,
        icon: "",
        component: "PortalHomeScreen",
        layout: "/portal",
        name: "Home",
        grade: "1",
    },
    {
        path: "/agent",
        uid: UIDs.screens.portal.agent,
        icon: "",
        component: "PortalAgentScreen",
        layout: "/portal",
        name: "Home",
        grade: "1",
    },
    {
        path: "/product",
        uid: UIDs.screens.portal.product,
        icon: "",
        component: "PortalProductScreen",
        layout: "/portal",
        name: "Product",
        grade: "1",
    },
    {
        path: "/pricing",
        uid: UIDs.screens.portal.pricing,
        icon: "",
        component: "PortalPricingScreen",
        layout: "/portal",
        name: "Pricing",
        grade: "1",
    },
    {
        path: "/partner",
        uid: UIDs.screens.portal.partner,
        icon: "",
        component: "PortalPartnerScreen",
        layout: "/portal",
        name: "Partner",
        grade: "1",
    },
    {
        path: "/jobs",
        uid: UIDs.screens.portal.jobs,
        icon: "",
        component: "PortalJobsScreen",
        layout: "/portal",
        name: "Jobs",
        grade: "1",
    },
    {
        path: "/contact",
        uid: UIDs.screens.portal.contact,
        icon: "",
        component: "PortalContactScreen",
        layout: "/portal",
        name: "Contact",
        grade: "1",
    },
    {
        path: "/download",
        uid: UIDs.screens.portal.download,
        icon: "",
        component: "PortalDownloadScreen",
        layout: "/portal",
        name: "Download",
        grade: "1",
    },
    {
        path: "/downloadapp",
        uid: UIDs.screens.portal.downloadapp,
        icon: "",
        component: "PortalDownloadAppScreen",
        layout: "/portal",
        name: "DownloadApp",
        grade: "1",
    },
    {
        path: "/studentUse",
        uid: UIDs.screens.portal.studentuse,
        icon: "",
        component: "PortalStudentUseScreen",
        layout: "/portal",
        name: "studentUse",
        grade: "1",
    },
    {
        path: "/teacherUse",
        uid: UIDs.screens.portal.teacheruse,
        icon: "",
        component: "PortalTeacherUseScreen",
        layout: "/portal",
        name: "teacheruse",
        grade: "1",
    },
    {
        path: "/termsCondition",
        uid: UIDs.screens.portal.termscondition,
        icon: "",
        component: "PortalTermsConditionScreen",
        layout: "/portal",
        name: "teacheruse",
        grade: "1",
    },
    {
        path: "/privacy",
        uid: UIDs.screens.portal.privacy,
        icon: "",
        component: "PortalPrivacyScreen",
        layout: "/portal",
        name: "privacy",
        grade: "1",
    },
    {
        path: "/rewardterms",
        uid: UIDs.screens.portal.rewardterms,
        icon: "",
        component: "PortalRewardTermsScreen",
        layout: "/portal",
        name: "rewardterms",
        grade: "1",
    },
    {
        path: "/roomvipterms",
        uid: UIDs.screens.portal.roomvipterms,
        icon: "",
        component: "PortalRoomVipTermsScreen",
        layout: "/portal",
        name: "roomvipterms",
        grade: "1",
    },
    {
        path: "/aboutus",
        uid: UIDs.screens.portal.aboutus,
        icon: "",
        component: "PortalAboutUsScreen",
        layout: "/portal",
        name: "aboutus",
        grade: "1",
    },
    {
        path: "/homework",
        uid: UIDs.screens.portal.homework,
        icon: "",
        component: "PortalHomeworkScreen",
        layout: "/portal",
        name: "homework",
        grade: "1",
    },
    {
        path: "/talkchinese",
        uid: UIDs.screens.portal.talkchinese,
        icon: "",
        component: "PortalTalkChineseScreen",
        layout: "/portal",
        name: "talkchinese",
        grade: "1",
    },
    {
        path: "/chinesepark",
        uid: UIDs.screens.portal.chinesepark,
        icon: "",
        component: "PortalChineseParkScreen",
        layout: "/portal",
        name: "chinesepark",
        grade: "1",
    },
    {
        path: "/userhomeworkunit/:id",
        uid: UIDs.screens.plain.userhomeworkunit,
        icon: "",
        component: "PlainUserHomeworkUnitScreen",
        layout: "/plain",
    },
    {
        path: "/whiteboard",
        uid: UIDs.screens.plain.whiteboard,
        icon: "",
        component: "PlainWhiteboardScreen",
        layout: "/plain",
    },
    {
        path: "/teachershare/:id/:userId/",
        uid: UIDs.screens.plain.teachershare,
        component: "PlainTeacherShareScreen",
        layout: "/plain",
    },
    {
        path: "/studentshare/:id/:userId/:email",
        uid: UIDs.screens.plain.studentshare,
        component: "PlainStudentShareScreen",
        layout: "/plain",
    },
    {
        path: "/studentshare/:id/:userId",
        uid: UIDs.screens.plain.studentshare,
        component: "PlainStudentShareScreen",
        layout: "/plain",
    },
    {
        path: "/promotiondetails/:id/:userId",
        uid: UIDs.screens.plain.promotiondetails,
        component: "PlainPromotionDetailsScreen",
        layout: "/plain",
    },
    {
        path: "/joinclass/:id",
        uid: UIDs.screens.plain.joinclass,
        component: "PlainJoinClassScreen",
        layout: "/plain",
    },
    {
        path: "/joinclasses/:classId",
        uid: UIDs.screens.plain.joinclass,
        component: "PlainJoinClassScreen",
        layout: "/plain",
    },
    {
        path: "/default",
        uid: MobileUIDs.screens.homework.newdefault,
        component: "NewDefaultScreen",
        layout: "/mobile",
    },
    {
        path: "/login",
        uid: MobileUIDs.screens.account.login,
        component: "MobileLoginScreen",
        layout: "/mobile",
    },
    {
        path: "/homeworklist",
        uid: MobileUIDs.screens.homework.newlist,
        component: "NewListScreen",
        layout: "/mobile",
    },
    {
        path: "/historylist",
        uid: MobileUIDs.screens.homework.newhistorylist,
        component: "NewHistoryListScreen",
        layout: "/mobile",
    },
    {
        path: "/homework",
        uid: MobileUIDs.screens.homework.homeworkunit,
        component: "HomeworkUnitScreen",
        layout: "/mobile",
    },
    {
        path: "/homeworkredo",
        uid: MobileUIDs.screens.homework.homeworkunitredo,
        component: "HomeworkUnitRedoScreen",
        layout: "/mobile",
    },
    {
        path: "/homeworkcompleteredo",
        uid: MobileUIDs.screens.homework.homeworkcompleteredo,
        component: "HomeworkCompleteRedoScreen",
        layout: "/mobile",
    },
    {
        path: "/homeworkcomplete",
        uid: MobileUIDs.screens.homework.homeworkcomplete,
        component: "HomeworkCompleteScreen",
        layout: "/mobile",
    },
    {
        path: "/completehomework",
        uid: MobileUIDs.screens.homework.completehomework,
        component: "CompleteHomeworkScreen",
        layout: "/mobile",
    },
    {
        path: "/homeworkhistory",
        uid: MobileUIDs.screens.homework.homeworkhistory,
        component: "HomeworkHistoryScreen",
        layout: "/mobile",
    },
    {
        path: "/homeworkpeerrevise",
        uid: MobileUIDs.screens.homework.homeworkpeerrevise,
        component: "HomeworkPeerReviseScreen",
        layout: "/mobile",
    },
    {
        path: "/peerrevisedetail",
        uid: MobileUIDs.screens.homework.peerrevisedetail,
        component: "PeerReviseDetailScreen",
        layout: "/mobile",
    },
    {
        path: "/replylist",
        uid: MobileUIDs.screens.homework.replylist,
        component: "ReplyListScreen",
        layout: "/mobile",
    },
    {
        path: "/practiceresult",
        uid: MobileUIDs.screens.homework.practiceresult,
        component: "PracticeResultScreen",
        layout: "/mobile",
    },
    {
        path: "/reviewlist",
        uid: MobileUIDs.screens.homework.reviewlist,
        component: "ReviewListScreen",
        layout: "/mobile",
    },
    {
        path: "/reviewunit",
        uid: MobileUIDs.screens.homework.reviewunit,
        component: "ReviewUnitScreen",
        layout: "/mobile",
    },
    {
        path: "/checkunit",
        uid: MobileUIDs.screens.homework.checkunit,
        component: "CheckUnitScreen",
        layout: "/mobile",
    },
    {
        path: "/checkcomplete",
        uid: MobileUIDs.screens.homework.checkcomplete,
        component: "CheckCompleteScreen",
        layout: "/mobile",
    },
    {
        path: "/article",
        uid: MobileUIDs.screens.homework.article,
        component: "ArticleScreen",
        layout: "/mobile",
    },
    {
        path: "/historypreview",
        uid: MobileUIDs.screens.homework.historypreview,
        component: "HistoryPreviewScreen",
        layout: "/mobile",
    },
    {
        path: "/redohomeworkcheckunit",
        uid: MobileUIDs.screens.homework.redohomeworkcheckunit,
        component: "RedoHomeworkCheckUnitScreen",
        layout: "/mobile",
    },
    {
        path: "/learn/default",
        uid: MobileUIDs.screens.learn.default,
        component: "MobileLearnDefaultScreen",
        layout: "/mobile",
    },
    {
        path: "/learn/search",
        uid: MobileUIDs.screens.learn.search,
        component: "MobileSearchCourseScreen",
        layout: "/mobile",
    },
    {
        path: "/learn/productcourse",
        uid: MobileUIDs.screens.learn.productcourse,
        component: "MobileProductCourseScreen",
        layout: "/mobile",
    },
    {
        path: "/learn/searchword",
        uid: MobileUIDs.screens.learn.searchword,
        component: "MobileSearchWordScreen",
        layout: "/mobile",
    },
    {
        path: "/learn/bookdetail",
        uid: MobileUIDs.screens.learn.bookdetail,
        component: "MobileBookDetailScreen",
        layout: "/mobile",
    },
    {
        path: "/learn/commentlist",
        uid: MobileUIDs.screens.learn.commentlist,
        component: "MobileCommentListScreen",
        layout: "/mobile",
    },
    {
        path: "/learn/addcomment",
        uid: MobileUIDs.screens.learn.addcomment,
        component: "MobileAddCommentScreen",
        layout: "/mobile",
    },
    {
        path: "/learn/practice",
        uid: MobileUIDs.screens.learn.practice,
        component: "MobileLearnPracticeScreen",
        layout: "/mobile",
    },
    {
        path: "/learn/complete",
        uid: MobileUIDs.screens.learn.complete,
        component: "MobileLearnCompleteScreen",
        layout: "/mobile",
    },
    {
        path: "/studyletter/default",
        uid: MobileUIDs.screens.studyletter.default,
        component: "StudyLetterDefaultScreen",
        layout: "/mobile",
    },
    {
        path: "/studyletter/tasklist",
        uid: MobileUIDs.screens.studyletter.taskList,
        component: "StudyLetterTaskListScreen",
        layout: "/mobile",
    },
    {
        path: "/studyletter/letterlist",
        uid: MobileUIDs.screens.studyletter.letterList,
        component: "StudyLetterLetterListScreen",
        layout: "/mobile",
    },
    {
        path: "/studyletter/statedesc",
        uid: MobileUIDs.screens.studyletter.stateDesc,
        component: "StudyLetterStateDescScreen",
        layout: "/mobile",
    },
    {
        path: "/studyletter/letterdetails",
        uid: MobileUIDs.screens.studyletter.letterDetails,
        component: "StudyLetterLetterDetailsScreen",
        layout: "/mobile",
    },
    {
        path: "/studyletter/wordlist",
        uid: MobileUIDs.screens.studyletter.wordList,
        component: "StudyLetterWordListScreen",
        layout: "/mobile",
    },
    {
        path: "/studyletter/worddetails",
        uid: MobileUIDs.screens.studyletter.wordDetails,
        component: "StudyLetterWordDetailsScreen",
        layout: "/mobile",
    },
    {
        path: "/studyletter/practice",
        uid: MobileUIDs.screens.studyletter.practice,
        component: "StudyLetterPracticeScreen",
        layout: "/mobile",
    },
    {
        path: "/studyletter/complete",
        uid: MobileUIDs.screens.studyletter.complete,
        component: "StudyLetterCompleteScreen",
        layout: "/mobile",
    },
    {
        path: "/studyletter/statisticletterlist",
        uid: MobileUIDs.screens.studyletter.statisticletterList,
        component: "StudyLetterStatisticLetterListScreen",
        layout: "/mobile",
    },
    {
        path: "/studyletter/toplist",
        uid: MobileUIDs.screens.studyletter.topList,
        component: "StudyLetterTopListScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/home",
        uid: MobileUIDs.screens.talkchinese.home,
        component: "TalkChineseHomeScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/reviewlist",
        uid: MobileUIDs.screens.talkchinese.reviewlist,
        component: "TalkChineseReviewListScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/historylist",
        uid: MobileUIDs.screens.talkchinese.historylist,
        component: "TalkChineseHistoryListScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/history",
        uid: MobileUIDs.screens.talkchinese.history,
        component: "TalkChineseHistoryScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/historypreview",
        uid: MobileUIDs.screens.talkchinese.historypreview,
        component: "TalkChineseHistoryPreviewScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/ranking",
        uid: MobileUIDs.screens.talkchinese.ranking,
        component: "TalkChineseRankingScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/default",
        uid: MobileUIDs.screens.talkchinese.default,
        component: "TalkChineseDefaultScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/plan",
        uid: MobileUIDs.screens.talkchinese.plan,
        component: "TalkChinesePlanScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/practiceresult",
        uid: MobileUIDs.screens.talkchinese.practiceresult,
        component: "TalkChinesePracticeResultScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/complete",
        uid: MobileUIDs.screens.talkchinese.complete,
        component: "TalkChineseCompleteScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/level",
        uid: MobileUIDs.screens.talkchinese.level,
        component: "TalkChineseLevelScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/practice",
        uid: MobileUIDs.screens.talkchinese.practice,
        component: "TalkChinesePracticeScreen",
        layout: "/mobile",
    },
    {
        path: "/talkchinese/settings",
        uid: MobileUIDs.screens.talkchinese.settings,
        component: "TalkChineseSettingsScreen",
        layout: "/mobile",
    },
    {
        path: "/me/parentemail",
        uid: MobileUIDs.screens.me.parentEmail,
        component: "ParentEmailScreen",
        layout: "/mobile",
    },

    {
        path: "/chinesepark/default",
        uid: MobileUIDs.screens.chinesepark.default,
        component: "ChineseparkDefaultScreen",
        icon: "",
        layout: "/mobile",
        name: "chinesepark",
        grade: "1",
    },
    {
        path: "/chinesepark/roomsetting",
        uid: MobileUIDs.screens.chinesepark.roomSetting,
        component: "ChineseparkRoomSettingScreen",
        icon: "",
        layout: "/mobile",
        name: "chineseparkroomsetting",
    },
    {
        path: "/chinesepark/roommanager",
        uid: MobileUIDs.screens.chinesepark.roomManager,
        component: "ChineseparkRoomManagerScreen",
        layout: "/mobile",
    },
    {
        path: "/chinesepark/blacklist",
        uid: MobileUIDs.screens.chinesepark.blackList,
        component: "ChineseparkRoomBlackListScreen",
        layout: "/mobile",
    },
    {
        path: "/chinesepark/vipsetting",
        uid: MobileUIDs.screens.chinesepark.vipFeatures,
        component: "ChineseparkVipFeaturesScreen",
        icon: "",
        layout: "/mobile",
    },
    {
        path: "/chinesepark/createroom",
        uid: MobileUIDs.screens.chinesepark.createRoom,
        component: "ChineseparkCreateRoomScreen",
        layout: "/mobile",
    },
    {
        path: "/chinesepark/pkrules",
        uid: MobileUIDs.screens.chinesepark.pkRules,
        component: "ChineseparkPkRulesScreen",
        layout: "/mobile",
    },
    {
        path: "/:id",
        uid: MobileUIDs.screens.chinesepark.room,
        component: "ChineseParkRoomScreen",
        layout: "/room",
    },
    {
        path: "/bbs/default",
        uid: MobileUIDs.screens.bbs.default,
        component: "MobileBbsDefaultScreen",
        layout: "/mobile",
    },
    {
        path: "/bbs/details",
        uid: MobileUIDs.screens.bbs.details,
        component: "MobileBbsDetailsScreen",
        layout: "/mobile",
    },
    {
        path: "/bbs/message",
        uid: MobileUIDs.screens.bbs.message,
        component: "MobileBbsMessageScreen",
        layout: "/mobile",
    },
    {
        path: "/bbs/search",
        uid: MobileUIDs.screens.bbs.search,
        component: "MobileBbsSearchScreen",
        layout: "/mobile",
    },
    {
        path: "/me/default",
        uid: MobileUIDs.screens.me.default,
        component: "MobileMeDefaultScreen",
        layout: "/mobile",
    },
    {
        path: "/me/profilesetting",
        uid: MobileUIDs.screens.me.profileSetting,
        component: "MobileMeProfileSettingScreen",
        layout: "/mobile",
    },
    {
        path: "/me/studyplanlist",
        uid: MobileUIDs.screens.me.studyPlanList,
        component: "MobileMeStudyPlanListScreen",
        layout: "/mobile",
    },
    {
        path: "/me/rewardplanlist",
        uid: MobileUIDs.screens.me.rewardPlanList,
        component: "MobileMeRewardPlanListScreen",
        layout: "/mobile",
    },
    {
        path: "/me/feedbacksuggestion",
        uid: MobileUIDs.screens.me.feedbackSuggestion,
        component: "MobileMeFeedbackSuggestionScreen",
        layout: "/mobile",
    },
    {
        path: "/me/member",
        uid: MobileUIDs.screens.me.member,
        component: "MobileMeMemberScreen",
        layout: "/mobile",
    },
    {
        path: "/me/invitefriend",
        uid: MobileUIDs.screens.me.inviteFriend,
        component: "MobileMeInviteFriendScreen",
        layout: "/mobile",
    },
    {
        path: "/me/myclass",
        uid: MobileUIDs.screens.me.myClass,
        component: "MobileMeMyClassScreen",
        layout: "/mobile",
    },
    {
        path: "/me/mywallet",
        uid: MobileUIDs.screens.me.myWallet,
        component: "MobileMeMyWalletScreen",
        layout: "/mobile",
    },
    {
        path: "/me/paymenthistory",
        uid: MobileUIDs.screens.me.paymentHistory,
        component: "MobileMePaymentHistoryScreen",
        layout: "/mobile",
    },
    {
        path: "/me/notification",
        uid: MobileUIDs.screens.me.notification,
        component: "MobileMeNotificationScreen",
        layout: "/mobile",
    },
    {
        path: "/me/language",
        uid: MobileUIDs.screens.me.language,
        component: "MobileMeLanguageScreen",
        layout: "/mobile",
    },
    {
        path: "/me/about",
        uid: MobileUIDs.screens.me.about,
        component: "MobileMeAboutScreen",
        layout: "/mobile",
    },
    {
        path: "/me/deleteaccount",
        uid: MobileUIDs.screens.me.deleteAccount,
        component: "MobileMeDeleteAccountScreen",
        layout: "/mobile",
    },
    {
        path: "/me/serversetting",
        uid: MobileUIDs.screens.me.serverSetting,
        component: "MobileMeServerSettingScreen",
        layout: "/mobile",
    },
    {
        path: "/me/recordsetting",
        uid: MobileUIDs.screens.course.recordsetting,
        component: "MobileMeRecordsettingScreen",
        layout: "/mobile",
    },
    {
        path: "/me/resetpassword",
        uid: MobileUIDs.screens.me.resetPassword,
        component: "MobileMeResetPasswordScreen",
        layout: "/mobile",
    },
    {
        path: "/me/linkemail",
        uid: MobileUIDs.screens.me.linkEmail,
        component: "MobileMeLinkEmailScreen",
        layout: "/mobile",
    },
    {
        path: "/me/parentemail",
        uid: MobileUIDs.screens.me.parentEmail,
        component: "MobileMeParentEmailScreenScreen",
        layout: "/mobile",
    },
    {
        path: "/me/makePayment",
        uid: MobileUIDs.screens.me.makePayment,
        component: "MobileMeMakePaymentScreen",
        layout: "/mobile",
    },
    {
        path: "/me/fundsDetails",
        uid: MobileUIDs.screens.me.fundsDetails,
        component: "MobileMeFundsDetailsScreen",
        layout: "/mobile",
    },
    {
        path: "/me/withdraw",
        uid: MobileUIDs.screens.me.withdraw,
        component: "MobileMeWithdrawScreen",
        layout: "/mobile",
    },
    {
        path: "/me/bankAccounts",
        uid: MobileUIDs.screens.me.bankAccounts,
        component: "MobileMeBankAccountScreen",
        layout: "/mobile",
    },
    {
        path: "/me/editBankAccount",
        uid: MobileUIDs.screens.me.editBankAccount,
        component: "MobileMeEditBankAccountScreen",
        layout: "/mobile",
    },
    {
        path: "/me/roomHistory",
        uid: MobileUIDs.screens.me.roomHistory,
        component: "MobileMeRoomHistoryScreen",
        layout: "/mobile",
    },
    {
        path: "/me/roomHistoryDetails",
        uid: MobileUIDs.screens.me.roomHistoryDetails,
        component: "MobileMeRoomHistoryDetailsScreen",
        layout: "/mobile",
    },
    {
        path: "/aichat/chat",
        uid: MobileUIDs.screens.aichat.chat,
        component: "MobileAiChatChatScreen",
        layout: "/mobile",
    },
];

export default Routes;
